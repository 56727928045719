import React, { useRef } from "react";
import small from "../../videos/small.mp4";
import medium from "../../videos/medium.mp4";
import large from "../../videos/large.mp4";
import bestSeller from "../../images/bestSeller.png";

const Prezzi = (props) => {
    const videoRefs = useRef(props.pacchetti.map(() => React.createRef()));

    const playVideo = (index) => {
        if (videoRefs.current && videoRefs.current[index].current) {
            videoRefs.current[index].current.play();
        }
    };

    const pauseVideo = (index) => {
        if (videoRefs.current[index].current) {
            videoRefs.current[index].current.pause();
        }
    };

    const onClickCard = () => {
        window.location.href = "/login";
    };

    const styleInfo = {
        flex: "1",
    };

    return (
        <div
            ref={props.prezziRef}
            className="sezione-100percent profileHome position-relative overflow-hidden"
        >
            <div
                style={{ width: "80%" }}
                className="row justify-content-center"
            >
                <div className="col-12">
                    <div className="row">
                        <div className="col d-flex justify-content-center text-color p-0">
                            <h2 className="text-uppercase HeeboBold">
                                i nostri pacchetti
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center text-color">
                            <h6 className="text-uppercase HeeboBold text-center">
                                Non ci sono costi fissi, paghi solo le call
                                prenotate da prospect interessati al tuo
                                prodotto o servizio
                            </h6>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        {props.pacchetti.length > 0 &&
                            props.pacchetti.map((pacchetto, index) => {
                                const features = JSON.parse(pacchetto.features);
                                return (
                                    <div
                                        className="col-sm-12 col-lg-4 p-2"
                                        key={index}
                                    >
                                        <div
                                            style={{
                                                height:
                                                    pacchetto.in_evidenza ===
                                                    "1"
                                                        ? 550
                                                        : 500,
                                            }}
                                            className="card mb-5 mb-lg-0"
                                        >
                                            <img
                                                alt=""
                                                className="img-price"
                                                src={require(`../../images/${pacchetto.nome}.png`)}
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    height: "100%",
                                                    width: "100%",
                                                    objectFit: "cover",
                                                    zIndex: 0,
                                                    borderRadius: "10px",
                                                }}
                                            />
                                            <div
                                                onMouseEnter={() =>
                                                    playVideo(index)
                                                }
                                                onMouseLeave={() =>
                                                    pauseVideo(index)
                                                }
                                                style={{ height: "100%" }}
                                            >
                                                <video
                                                    className="video-price"
                                                    ref={
                                                        videoRefs.current[index]
                                                    }
                                                    src={
                                                        pacchetto.nome ===
                                                        "Small"
                                                            ? small
                                                            : pacchetto.nome ===
                                                              "Medium"
                                                            ? medium
                                                            : large
                                                    }
                                                    alt=""
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        zIndex: 0,
                                                        borderRadius: "10px",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor:
                                                            "rgba(0,0,0,0.5)",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        zIndex: 1,
                                                        borderRadius: "10px",
                                                    }}
                                                />
                                                <div
                                                    className="card-body position-relative"
                                                    style={{ height: "100%" }}
                                                >
                                                    {pacchetto.in_evidenza ===
                                                        "1" && (
                                                        <img
                                                            src={bestSeller}
                                                            alt="best seller"
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                                top: -50,
                                                                right: -26,
                                                                width: "40%",
                                                                zIndex: 9,
                                                            }}
                                                        />
                                                    )}
                                                    <div>
                                                        <h3 className="card-title text-uppercase text-center text-light">
                                                            {pacchetto.nome}
                                                        </h3>
                                                        <p className="card-price text-center text-light">
                                                            <span>
                                                                1° mese{" "}
                                                            </span>
                                                            <span className="fs-1">
                                                                {
                                                                    pacchetto.prezzo_offerta
                                                                }
                                                                €
                                                            </span>
                                                            <span className="fs-5">
                                                                {" "}
                                                                + IVA
                                                            </span>
                                                        </p>
                                                        <p className="text-center fs-6 text-light">
                                                            rinnovo successivo
                                                        </p>
                                                        <p className="card-price text-center text-light">
                                                            <span className="fs-5">
                                                                {
                                                                    pacchetto.prezzo
                                                                }
                                                                €
                                                            </span>
                                                            <span className="fs-5">
                                                                {" "}
                                                                + IVA
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: 1,
                                                            backgroundColor:
                                                                "gray",
                                                        }}
                                                    />
                                                    <div>
                                                        <h5 className="card-title text-uppercase text-center mb-2 text-light  fs-6">
                                                            <span
                                                                style={{
                                                                    fontSize: 25,
                                                                }}
                                                            >
                                                                {
                                                                    pacchetto.num_chiamate
                                                                }
                                                            </span>{" "}
                                                            aziende contattate
                                                            ogni mese
                                                        </h5>
                                                        <div className="d-grid">
                                                            <button
                                                                href="#"
                                                                className="btn btn-pricing text-uppercase"
                                                                onClick={() =>
                                                                    onClickCard(
                                                                        pacchetto.id
                                                                    )
                                                                }
                                                            >
                                                                Acquista
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: 1,
                                                            backgroundColor:
                                                                "gray",
                                                        }}
                                                    />
                                                    <div
                                                        className="d-flex flex-column justify-content-start card-title text-uppercase text-light"
                                                        style={{
                                                            fontSize: "12px",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        {pacchetto.features &&
                                                            features.map(
                                                                (
                                                                    feature,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <span
                                                                            key={
                                                                                index
                                                                            }
                                                                            style={
                                                                                styleInfo
                                                                            }
                                                                            className="text-center"
                                                                        >
                                                                            {
                                                                                feature
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Prezzi;
