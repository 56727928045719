import React from "react";
import { Box, Button, Modal, Paper, Stack } from '@mui/material'; 
import { FormControl, TextField } from "@mui/material";
import { CloseIcon } from "@material-ui/data-grid";  
import Cookies from "universal-cookie";
import addIpAddress from "../../services/addIpAddress";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

export function VMModal({ open, onClose }) { 
    const [ip, setIp] = React.useState("");

    const addIP = async() => { 
        const res = await addIpAddress(username, token, ip);
        if (res.result === "ok"){
            onClose();
        }
    }

    return ( 
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <Paper sx={{ maxWidth: 800, p: 3, width: '100%' }}>
                    <Stack direction="column" spacing={2}>
                        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
                        <FormControl fullWidth>
                            <TextField
                                label="IP Address"
                                variant="outlined"
                                value={ip}
                                onChange={(e) => setIp(e.target.value)}
                            />
                        </FormControl>
                        <FormControl >
                            <Button variant="contained" color="primary" onClick={addIP}>
                                Aggiungi
                            </Button>
                        </FormControl>
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    );
}