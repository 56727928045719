import React from "react";
import Cookies from "universal-cookie";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import { TextField } from "@material-ui/core";
import { Alert, Stack, Snackbar } from "@mui/material";
import LazyLoading from "react-list-lazy-load";
import ReactList from "react-list";

//API CALLS
import getAllObiettivoPageViews from "../../services/getAllObiettivoPageViews";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
let type = cookie.get("tipo", { path: "/" });
const id = cookie.get("id", { path: "/" });

const headCells = [
	{
		width: 100,
		label: "Nome",
		id: "nome",
		numeric: false,
	},
	{
		width: 100,
		label: "Obiettivo",
		id: "obiettivo",
		numeric: false,
	},
	{
		width: 100,
		label: "Link",
		id: "data",
		numeric: false,
	},
	{
		width: 100,
		label: "Elenco dei click",
		id: "history",
		numeric: false,
	},
];
export default class PageViewsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			toastAlert: {
				open: false,
				message: "",
				severity: "success",
			},
			filter: "",
			user: 0,
			total: 0,
			loading: true,
		};
	}

	tableBodyRef = React.createRef();

	handleApiResponse = (response, type) => {
		let toastAlert = this.state.toastAlert;
		if (response === "ok") {
			toastAlert = { open: true, message: type + " aggiornato con successo", severity: "success" };
			setTimeout(() => {
				this.setState({ toastAlert: { open: false, message: "", severity: "success" } });
			}, 3000);
		} else {
			toastAlert = { open: true, message: type + " non aggiornato", severity: "error" };
		}
		this.setState({ toastAlert });
	};

	handleFilter = async filter => {
		this.setState({ loading: true });
		let res = await getAllObiettivoPageViews(username, token, filter, this.props.selectedUser);
		this.setState({ rows: res.data, total: res.total, loading: false });
	};

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedUser !== prevProps.selectedUser) {
			this.reload();
		}
	}

	async reload() {
		this.setState({ loading: true });
		let resAllContattiClienti = await getAllObiettivoPageViews(username, token, "", this.props.selectedUser);
		this.setState({
			loading: false,
			rows: resAllContattiClienti.data,
			total: resAllContattiClienti.total,
		});
	}

	render() {
		return (
			<div
				style={{
					width: "92%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={this.state.toastAlert.open}
						autoHideDuration={6000}
						onClose={this.handleCloseAlert}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
					>
						<Alert
							onClose={this.handleCloseAlert}
							severity={this.state.toastAlert.severity}
							sx={{ width: "100%" }}
						>
							{this.state.toastAlert.message}
						</Alert>
					</Snackbar>
				</Stack>

				{/* HEADER */}
				<div className="d-flex align-items-center align-content-center">
					<TextField
						InputProps={{
							disableUnderline: true,
						}}
						label="Cerca"
						variant="standard"
						className="p-0 me-2 mb-2"
						onChange={event => {
							this.setState({ filter: event.target.value });
						}}
						onKeyDown={event => {
							if (event.key === "Enter") {
								this.handleFilter(event.target.value);
							}
						}}
					/>
				</div>
				<div className="d-flex">
					<p className="mb-1 me-2">
						<strong>Email raccolte: </strong> {this.state.total}
					</p>
				</div>

				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "75vh" }} key="table_container">
					<Table striped bordered hover responsive key="table" ref={this.tableBodyRef}>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell key={headCell.id} style={{ width: headCell.width + "px" }}>
										{headCell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<>
							{!this.state.loading && this.state.rows.length > 0 && (
								<LazyLoading
									length={this.state.rows.length}
									items={this.state.rows}
									onRequestPage={() => {}}
								>
									<ReactList
										itemsRenderer={(items, ref) => (
											<TableBody key="table_body" ref={ref}>
												{items}
											</TableBody>
										)}
										itemRenderer={(index, key) => {
											let row = this.state.rows[index];
											return (
												<TableRow key={key}>
													<TableCell>{row.nome}</TableCell>
													<TableCell>{row.obiettivo}</TableCell>
													<TableCell>{row.data}</TableCell>
													<TableCell>
														{JSON.parse(row.history) &&
															JSON.parse(row.history).length > 0 &&
															JSON.parse(row.history).map(click => {
																return (
																	<div>
																		{click.created_at}
																		<br />{" "}
																	</div>
																);
															})}
													</TableCell>
												</TableRow>
											);
										}}
										length={this.state.rows.length}
										type={"simple"}
									/>
								</LazyLoading>
							)}

							{this.state.loading && (
								<TableRow style={{ height: "60vh" }}>
									<TableCell colSpan={10}>
										<div
											className="d-flex justify-content-center align-items-center"
											style={{ height: "100%" }}
										>
											<ReactLoading type="bubbles" color="orange" height={"15%"} width={"15%"} />
										</div>
									</TableCell>
								</TableRow>
							)}
							{!this.state.loading && this.state.rows.length === 0 && (
								<tr>
									<TableCell colSpan={headCells.length} style={{ textAlign: "center" }}>
										Nessun risultato
									</TableCell>
								</tr>
							)}
						</>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
