import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import SideNav, { NavItem } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import logout from "../services/logout";
import * as Icon from "react-feather";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from "@mui/material";
import NotifyContext from "../context/NotifyContext";
import logo from "../images/Honey&bees3.png";
import getUtenti from "../services/getUtenti";

const cookie = new Cookies();
const type = cookie.get("tipo", { path: "/" });
const id = cookie.get("id", { path: "/" });
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const AdminSidebar = () => {
	const { selectedUser, setSelectedUser, countNotify } = useContext(NotifyContext);

	const [username, setUsername] = useState("");
	const [token, setToken] = useState("");
	const [hoveredLink, setHoveredLink] = useState(null);
	const [users, setUsers] = useState([]);

	const path = window.location.href.split("/");

	const history = useHistory();

	const adminSidebar = {
		dashboard: {
			icon: <Icon.BarChart2 />,
			text: "Dashboard",
			url: "dashboard",
			auth: false,
			linkedin_account: true,
			tooltip:
				"La Dashboard di Honey&Bees è il tuo centro di controllo. Qui puoi avere una panoramica immediata delle tue attività recenti, performance dei lead, e avanzamenti nei contatti. Visualizza grafici e statistiche chiave per monitorare l'efficacia delle tue strategie di lead generation e adattarle di conseguenza.",
		},
		contatti: {
			icon: <Icon.Users />,
			text: "Contatti",
			url: "contatti",
			auth: false,
			linkedin_account: true,
			tooltip:
				"Qui puoi gestire tutti i tuoi contatti. Questa sezione ti consente di visualizzare informazioni dettagliate su ciascuno di essi, verificarne lo stato e quindi i progressi del suo avanzamento nel funnel.",
		},
		pageViews: {
			icon: <Icon.Paperclip />,
			text: "Page Views",
			url: "page-views",
			auth: false,
			tooltip: "Qui puoi visualizzare tutti gli utenti che hanno visitato le tue pagine.",
		},
		emails: {
			icon: <Icon.Mail />,
			text: "E-mails",
			url: "emails",
			auth: false,
			tooltip: "Qui puoi visualizzare tutti gli utenti che hanno lasciato la loro mail.",
		},
		chat: {
			icon: <Icon.MessageSquare />,
			text: "Chat",
			url: "chat",
			auth: false,
			linkedin_account: true,
			tooltip:
				"La sezione Chat ti permette di controllare i messaggi inviati ai contatti da tutti i tuoi account Linkdin.",
		},
		notifiche: {
			icon: <Icon.Bell />,
			text: "Notifiche",
			url: "notify",
			auth: false,
			tooltip: "La sezione Notifiche ti permette di controllare le notifiche ricevute.",
			counter: true,
		},
		info: {
			icon: <Icon.Info />,
			text: "Info",
			url: "info",
			auth: false,
			linkedin_account: true,
			tooltip:
				"Qui puoi personalizzare la tua esperienza su Honey&Bees. Configura la descrizione della tua attività in modo più dettagliato possibile. Inserisci tutte le informazioni che vuoi che siano comunicate ai tuoi clienti. Puoi inoltre aggiornare le tue informazioni personali e di fatturazione.",
		},
		campagne: {
			icon: <Icon.Layers />,
			text: "Campagne",
			url: "campagne",
			auth: true,
			tooltip: "In Campagne, puoi creare e gestire campagne di lead generation personalizzate.",
		},
		leadsList: {
			icon: <Icon.Clipboard />,
			text: "Leads List",
			url: "leads-list",
			auth: true,
			tooltip:
				"In Leads List, puoi creare elenchi personalizzati per la generazione dei lead da utilizzare nella tua ricerca di nuovi clienti.",
		},
		calls: {
			icon: <Icon.Phone />,
			text: "Calls",
			url: "calls",
			auth: true,
			tooltip:
				"Nella sezione Calls, trovi un elenco delle chiamate pianificate e completate. Questa funzionalità ti permette di gestire le tue call con i prospect, visualizzare i dettagli di ogni chiamata e accedere facilmente alle note o ai resoconti post-call per un follow-up efficace.",
		},
		liste: {
			icon: <Icon.List />,
			text: "Prospects",
			url: "prospects",
			auth: true,
			tooltip:
				"In Prospects, trovi l'elenco dei potenziali clienti identificati da Honey&Bees. Qui puoi vedere dettagli come il nome, la posizione, l'azienda e altre informazioni chiave che ti aiutano a personalizzare il tuo approccio.",
		},
		gestioneBot: {
			icon: <Icon.Linkedin />,
			text: "Accounts",
			url: "accounts",
			auth: true,
			tooltip: "Accounts è la sezione da cui puoi gestire le impostazioni dei tuoi account Linkedin.",
		},
		chatTest: {
			icon: <Icon.MessageCircle />,
			text: "Chat Test",
			url: "chat-test",
			auth: true,
			tooltip: "La sezione Chat Test ti permette di svolgere una conversazione con il nostro bot",
		},
		admin: {
			icon: <Icon.User />,
			text: "Admin",
			url: "admin",
			auth: true,
			tooltip: "La sezione Admin è dedicata agli utenti con ruoli amministrativi.",
		},
		list: {
			icon: <Icon.Clipboard />,
			text: "Liste",
			url: "liste",
			auth: true,
			tooltip: "In Liste, puoi creare e gestire elenchi personalizzati di contatti o prospect.",
		},
		vm: {
			icon: <Icon.Monitor />,
			text: "VM",
			url: "virtual-machine",
			auth: true,
			tooltip: "Elenco delle macchine virtuali da assegnare e già assegnate.",
		},
	};

	const handleLogout = async () => {
		await logout(username, token);
		cookie.remove("username");
		cookie.remove("token");
		cookie.remove("tipo");
		cookie.remove("id");
		history.push("/");
	};

	const getSidebar = typeSidebar => {
		return Object.keys(typeSidebar).map(key => {
			if (typeSidebar[key].auth && cookie.get("tipo", { path: "/" }) !== "1") return null;
			if (!typeSidebar[key].linkedin_account && cookie.get("tipo", { path: "/" }) === "3") return null;
			
			const tooltip = (
				<Tooltip
					title={typeSidebar[key].tooltip}
					placement="right"
					arrow
					componentsProps={{
						tooltip: {
							sx: {
								backgroundColor: "white",
								color: "black",
								fontSize: 14,
								borderRadius: 2,
								boxShadow: 1,
								p: 1,
								maxWidth: 300,
							},
						},
						arrow: {
							sx: {
								color: "white",
							},
						},
					}}
				>
					<span>
						<Icon.HelpCircle size={18} color={path[3] === typeSidebar[key].url ? "black" : "white"} />
					</span>
				</Tooltip>
			);

			//?contatore notifiche
			let counterBadge = null;
			if (typeSidebar[key].counter && countNotify > 0) {
				counterBadge = (
					<span
						style={{
							display: "inline-block",
							width: "12px",
							height: "12px",
							borderRadius: "50%",
							backgroundColor: "red",
							color: "white",
							fontSize: "10px",
							lineHeight: "12px",
							textAlign: "center",
							position: "absolute",
							left: "40px",
						}}
					>
						{countNotify}
					</span>
				);
			}

			return (
				<ListItem key={key} disablePadding>
					<ListItemButton
						onMouseEnter={() => setHoveredLink(key)}
						onMouseLeave={() => setHoveredLink(null)}
						onClick={() => history.push(`/${typeSidebar[key].url}`)}
						className={`${path[3] === typeSidebar[key].url ? "active" : "text-white"}`}
					>
						<ListItemIcon className={`${path[3] === typeSidebar[key].url ? "text-dark" : "text-white"}`}>
							{typeSidebar[key].icon}
							{counterBadge}
						</ListItemIcon>
						<ListItemText primary={typeSidebar[key].text} />
						{hoveredLink === key && typeSidebar[key].tooltip && tooltip}
					</ListItemButton>
				</ListItem>
			);
		});
	};

	const loadData = async () => {
		const username = cookie.get("username", { path: "/" });
		const token = cookie.get("token", { path: "/" });

		setUsername(username);
		setToken(token);
	};

	const getUsers = async () => {
		const username = cookie.get("username", { path: "/" });
		const token = cookie.get("token", { path: "/" });
		const res = await getUtenti(username, token);
		if (res && res.profili && res.profili.length > 0) {
			setUsers(res.profili);
		} else {
			console.log(res);
		}
	};

	useEffect(() => {
		loadData();
		getUsers();
	}, []);

	return (
		<>
			<SideNav
				defaultExpanded
				className="sidebar glass_background justify-content-between d-flex flex-column overflow-hidden"
			>
				<SideNav.Nav>
					<NavItem
						className="mb-5 mt-2 d-flex align-items-center p-3"
						eventKey="calls"
						onClick={() => history.push("/dashboard")}
					>
						<img src={logo} alt="" style={{ width: "auto", height: 50 }} />
					</NavItem>
					{type === "1" && (
						<Select
							value={selectedUser}
							onChange={e => {
								setSelectedUser(e.target.value);
							}}
							variant="filled"
							style={{
								backgroundColor: "white",
								borderRadius: 6,
								padding: 0,
								marginBottom: 20,
								minWidth: 400,
								width: "auto",
							}}
							label="Utente"
						>
							{users.map((user, i) => {
								return (
									<MenuItem value={user.id} key={user + i}>
										{user.nome_azienda + " - " + user.nome + " " + user.cognome}
									</MenuItem>
								);
							})}
						</Select>
					)}
					{getSidebar(adminSidebar)}
					<ListItem disablePadding>
						<ListItemButton onClick={handleLogout} className="text-white">
							<ListItemIcon>
								<Icon.LogOut color="white" />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</ListItemButton>
					</ListItem>
				</SideNav.Nav>
			</SideNav>
		</>
	);
};

export default AdminSidebar;
