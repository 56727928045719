import React, { useCallback, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import HeBLayout from "../components/HeBLayout";
import { Box, FormControl, Grid, InputLabel, TextField } from "@material-ui/core";
import { Button, Checkbox, Chip, MenuItem, OutlinedInput, Select, Stack, SvgIcon, Typography } from "@mui/material";
import Preferiti from "../components/Preferiti";
import getCampagnaId from "../services/getCampagnaId";
import editCampagna from "../services/editCampagna";
import getListeAvailable from "../services/getListeAvailable";
import updateWelcomeMessage from "../services/updateWelcomeMessage";
import { AddIcon, DragIcon } from "@material-ui/data-grid";
import { Trash } from "react-feather";
import NotifyContext from "../context/NotifyContext";

const cookies = new Cookies();
let username = cookies.get("username", { path: "/" });
let token = cookies.get("token", { path: "/" });
const id = cookies.get("id", { path: "/" });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CampagneEdit = ({ match, history }) => {
	const idCampagna = match.params.id;
	const { selectedUser } = useContext(NotifyContext);
	const [nome, setNome] = React.useState("");
	const [listeOptions, setListeOptions] = React.useState([]);
	const [listeSelected, setListeSelected] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [predefiniti, setPredefiniti] = React.useState([]);
	const [welcomeMessage, setWelcomeMessage] = React.useState(false);
	const [obiettivi, setObiettivi] = useState([]);
	const [dragIndex, setDragIndex] = useState(null);
	const [prompt, setPrompt] = React.useState("");

	const promptChoices = ["[[nome]]", "[[business]]", "[[city]]", "[[context]]", "[[nome utente]]"];

	const obiettivo = {
		obiettivo: null,
		forzato: 0,
		dati_aggiuntivi: null,
		context: null,
		ordine: 0,
	};

	const obiettiviDefault = ["call", "email", "link"];

	const reload = useCallback(async () => {
		await getCampagnaId(username, token, idCampagna, selectedUser).then(res => {
			setWelcomeMessage(res.data.welcome_message == "1" ? true : false);
			setObiettivi(res.data.obiettivi ? res.data.obiettivi : []);
			setNome(res.data.nome);
			setPrompt(res.data.prompt);
			const liste = JSON.parse(res.data.liste);
			const predefitiJson = JSON.parse(res.data.predefiniti);
			setPredefiniti(predefitiJson);
			if (liste[0] !== null) {
				const arr = [];
				liste.forEach(l => {
					if (arr.includes(l)) return;
					arr.push(parseInt(l));
				});
				setListeSelected(arr);
			}
		});
		await getListeAvailable(username, token, selectedUser, idCampagna).then(res => {
			setListeOptions(res.liste);
		});
		setLoading(false);
	}, [idCampagna]);

	useEffect(() => {
		reload();
	}, [reload]);

	const handleWelcomeMessage = async () => {
		setWelcomeMessage(!welcomeMessage);
		await updateWelcomeMessage(username, token, idCampagna, welcomeMessage ? 0 : 1);
	};

	const handleEditCampagna = async e => {
		const listeString = JSON.stringify(listeSelected);
		await editCampagna(
			username,
			token,
			nome,
			listeString,
			idCampagna,
			selectedUser,
			welcomeMessage ? 1 : 0,
			obiettivi,
			prompt
		);
		history.push("/campagne");
	};

	const handleChange = event => {
		const {
			target: { value },
		} = event;
		setListeSelected(typeof value === "string" ? value.split(",") : value);
	};

	const handleOrdine = objs => {
		const newObiettivi = [...objs];
		newObiettivi.map((o, i) => {
			o.ordine = i + 1;
			return o;
		});
		setObiettivi(newObiettivi);
	};

	const handleAddPredefinito = async () => {
		reload();
	};

	const handleDragStart = index => {
		setDragIndex(index);
	};

	const handleDragOver = e => {
		e.preventDefault();
	};

	const handleDrop = index => {
		const newObiettivi = [...obiettivi];
		const draggedObiettivo = newObiettivi[dragIndex];
		newObiettivi.splice(dragIndex, 1);
		newObiettivi.splice(index, 0, draggedObiettivo);
		handleOrdine(newObiettivi);
	};

	if (loading) {
		return (
			<HeBLayout>
				<div className="d-flex justify-content-center vh-100">
					<ReactLoading
						type={"bubbles"}
						color={"orange"}
						height={"10%"}
						width={"10%"}
						className="align-self-center "
					/>
				</div>
			</HeBLayout>
		);
	}
	return (
		<HeBLayout>
			<div
				style={{
					width: "90%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					display: "flex",
					justifyContent: "space-around",
				}}
			>
				<div style={{ marginTop: 50, width: "75%" }}>
					<Grid style={{ marginBottom: 50 }} item xs={6}>
						<Box style={{ color: "black" }}>
							<TextField
								label="Nome Campagna"
								variant="standard"
								value={nome}
								style={{ color: "black" }}
								onChange={e => setNome(e.target.value)}
								fullWidth
							/>
						</Box>
					</Grid>
					<Grid style={{ marginBottom: 50 }} item xs={6}>
						<Box>
							<InputLabel color="black" style={{ marginBottom: 10 }} id="demo-multiple-chip-label">
								Seleziona lista
							</InputLabel>
							<FormControl sx={{ m: 1, width: 300 }}>
								<Select
									sx={{
										width: 500,
										backgroundColor: "rgba(255,255,255,1)",
									}}
									labelId="demo-multiple-chip-label"
									id="demo-multiple-chip"
									multiple
									value={listeSelected}
									onChange={handleChange}
									input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
									renderValue={selected => {
										return (
											<Box
												sx={{
													display: "flex",
													flexWrap: "wrap",
													gap: 0.5,
												}}
											>
												{selected.map(value => {
													let opt = listeOptions.find(l => l.lista_id == value);
													if (opt)
														return (
															<Chip
																style={{
																	cursor: "pointer",
																}}
																key={value}
																label={opt.name}
															/>
														);
												})}
											</Box>
										);
									}}
									MenuProps={MenuProps}
								>
									{listeOptions.map(option => {
										return (
											<MenuItem
												disabled={
													option.campagna_id !== null &&
													option.campagna_id.toString() !== idCampagna
												}
												key={option.lista_id}
												value={parseInt(option.lista_id)}
											>
												{option.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					</Grid>
					<Grid item xs={7}>
						<Box sx={{ display: "flex" }}>
							<Checkbox
								checked={welcomeMessage}
								onChange={handleWelcomeMessage}
								inputProps={{ "aria-label": "controlled" }}
							/>
							<InputLabel style={{ marginTop: 15, color: "black" }} id="demo-multiple-chip-label">
								Messaggio di Benvenuto
							</InputLabel>
						</Box>
					</Grid>
				</div>
				<Preferiti
					obiettivi={obiettivi}
					idCampagna={idCampagna}
					selectedUser={selectedUser}
					predefiniti={predefiniti}
					onAddPredefinito={handleAddPredefinito}
				/>
			</div>
			<Stack direction="column" spacing={2} width={"100%"} px={10} mb={5}>
				<TextField
					fullWidth
					label="Prompt"
					name="prompt"
					value={prompt}
					variant="outlined"
					onChange={e => setPrompt(e.target.value)}
					InputLabelProps={prompt && { shrink: true }}
					multiline
					rows={5}
				/>
				<Stack direction="row" spacing={2} sx={{ mt: 1 }}>
					{promptChoices.map((choice, index) => (
						<Button key={index} variant="contained" onClick={() => setPrompt(prompt + " " + choice)}>
							{choice.replace("[[", "").replace("]]", "")}
						</Button>
					))}
				</Stack>
			</Stack>
			<Stack direction="column" spacing={2} width={"100%"} px={10}>
				<Box boxShadow={10} sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2 }}>
					<Typography variant="h6" sx={{ display: "flex" }}>
						Obiettivi della campagna
						<SvgIcon
							sx={{ ml: 1, cursor: "pointer" }}
							onClick={() => setObiettivi([...obiettivi, { ...obiettivo, ordine: obiettivi.length + 1 }])}
						>
							<AddIcon />
						</SvgIcon>
					</Typography>
					<Stack direction="column" spacing={2} sx={{ mt: 1 }}>
						{obiettivi &&
							obiettivi.length > 0 &&
							obiettivi.map((obiettivo, index) => {
								if (obiettivo.delete) return null;
								return (
									<Stack
										padding={2}
										direction="row"
										spacing={2}
										key={index}
										sx={{ alignItems: "center" }}
										draggable
										onDragStart={() => handleDragStart(index)}
										onDragOver={handleDragOver}
										onDrop={() => handleDrop(index)}
									>
										<SvgIcon sx={{ cursor: "grab" }}>
											<DragIcon />
										</SvgIcon>
										<TextField
											select
											fullWidth
											label="Obiettivo"
											name="obiettivo"
											value={obiettivo.obiettivo}
											variant="outlined"
											onChange={e => {
												const newObiettivi = [...obiettivi];
												newObiettivi[index].obiettivo = e.target.value;
												setObiettivi(newObiettivi);
											}}
											InputLabelProps={obiettivo.obiettivo && { shrink: true }}
										>
											{obiettiviDefault.map(option => (
												<MenuItem key={option} value={option}>
													{option}
												</MenuItem>
											))}
										</TextField>
										<TextField
											fullWidth
											label="Dati aggiuntivi"
											name="dati_aggiuntivi"
											value={obiettivo.dati_aggiuntivi}
											variant="outlined"
											onChange={e => {
												const newObiettivi = [...obiettivi];
												newObiettivi[index].dati_aggiuntivi = e.target.value;
												setObiettivi(newObiettivi);
											}}
											InputLabelProps={obiettivo.dati_aggiuntivi && { shrink: true }}
										/>
										<Stack direction="row" alignItems="center">
											<Checkbox
												checked={obiettivo.forzato}
												onChange={e => {
													const newObiettivi = [...obiettivi];
													newObiettivi[index].forzato = e.target.checked ? 1 : 0;
													setObiettivi(newObiettivi);
												}}
											/>
											<Typography>Forzato</Typography>
										</Stack>
										<Stack direction="column" width={"100%"}>
											<TextField
												fullWidth
												label="Contesto"
												name="context"
												value={obiettivo.context}
												variant="outlined"
												onChange={e => {
													const newObiettivi = [...obiettivi];
													newObiettivi[index].context = e.target.value;
													setObiettivi(newObiettivi);
												}}
												InputLabelProps={obiettivo.context && { shrink: true }}
												multiline
												rows={3}
											/>
											<p
												style={{
													cursor: "pointer",
													color: "blue",
													textDecoration: "underline",
												}}
												onClick={() => {
													const context = obiettivo.context ? obiettivo.context : "";
													const newContext = context + " [[dati_aggiuntivi]]";
													const newObiettivi = [...obiettivi];
													newObiettivi[index].context = newContext;
													setObiettivi(newObiettivi);
												}}
											>
												Dati aggiuntivi
											</p>
										</Stack>
										<SvgIcon
											sx={{ mt: 2, cursor: "pointer" }}
											onClick={() => {
												const deleteObiettivo = {
													...obiettivo,
													delete: true,
													ordine: null,
												};
												const newObiettivi = [...obiettivi, deleteObiettivo];
												newObiettivi.splice(index, 1);
												handleOrdine(newObiettivi);
											}}
										>
											<Trash />
										</SvgIcon>
									</Stack>
								);
							})}
					</Stack>
				</Box>
			</Stack>
			<Button
				style={{ marginTop: 50, marginLeft: 80 }}
				variant="contained"
				className="myButton"
				onClick={handleEditCampagna}
			>
				Salva
			</Button>
		</HeBLayout>
	);
};

export default CampagneEdit;
