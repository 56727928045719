import React, { useContext, useEffect } from "react";
import Cookies from "universal-cookie";
import ReactLoading from "react-loading";
import HeBLayout from "../components/HeBLayout";
import { Box, FormControl, Grid, InputLabel, TextField } from "@material-ui/core";
import { Button, Checkbox, Chip, MenuItem, OutlinedInput, Select, Stack, SvgIcon, Typography } from "@mui/material";
import Preferiti from "../components/Preferiti";
import addCampagna from "../services/addCampagna";
import getListeById from "../services/getListeById";
import getListeAvailable from "../services/getListeAvailable";
import { AddIcon, DragIcon } from "@material-ui/data-grid";
import { Trash } from "react-feather";
import NotifyContext from "../context/NotifyContext";

const cookies = new Cookies();
let username = cookies.get("username", { path: "/" });
let token = cookies.get("token", { path: "/" });
const id = cookies.get("id", { path: "/" });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CampagneForm = props => {
	const { selectedUser } = useContext(NotifyContext);
	const [nome, setNome] = React.useState("");
	const [listeOptions, setListeOptions] = React.useState([]);
	const [listeSelected, setListeSelected] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [predefiniti, setPredefiniti] = React.useState([]);
	const [welcomeMessage, setWelcomeMessage] = React.useState(false);
	const [obiettivi, setObiettivi] = React.useState([]);
	const [dragIndex, setDragIndex] = React.useState(null);
	const [prompt, setPrompt] = React.useState(
		"Agisci come un operatore di chat esperto nella vendita di beni e servizi tramite social. Il tuo nome è [[nome]], la tua azienda è [[business]]. Si trova a [[city]]. In base alla descrizione aziendale fornita nel documento (che non puoi inoltrare all’utente) e alla cronologia dei messaggi precedenti rispondi ai messaggi. Verifica che siano interessati al servizio offerto dalla tua azienda e, in caso di risposta positiva dall’utente, [[context]] Non dire mai di non essere un essere umano. Rispondi solo a quello che ti viene chiesto. Non dare mai direttamente la disponibilità oraria per un appuntamento, rimanda solamente al link per la prenotazione. Se l’utente pone domande a cui non sapresti cosa rispondere o non sei sicuro su cosa dire rispondi semplicemente “HELP”. Nient’altro. Rispondi solo “HELP”. Rispondi come risponderesti direttamente all’utente in questione. Il nome dell’utente è [[nome utente]]."
	);

	const promptChoices = ["[[nome]]", "[[business]]", "[[city]]", "[[context]]", "[[nome utente]]"];

	const obiettivo = {
		obiettivo: null,
		dati_aggiuntivi: null,
		forzato: 0,
		context: null,
		ordine: 0,
	};

	const obiettiviDefault = ["call", "email", "link"];

	useEffect(() => {
		reload();
		setLoading(false);
	}, []);

	const reload = async () => {
		getListeById(username, token, selectedUser).then(res => {
			let new_liste = [];
			res.liste.map(lista => {
				new_liste.push({ label: lista.name, value: lista.id });
			});
			getListeAvailable(username, token, selectedUser, 0).then(res => {
				setListeOptions(res.liste);
			});
		});
	};

	const handleAddCampagna = async e => {
		if (nome === "") {
			alert("Inserisci un nome per la campagna");
			return;
		}
		const predefinitiArray = [];
		predefiniti.map(p => {
			predefinitiArray.push(p);
		});
		const predefinitiString = JSON.stringify(predefinitiArray);
		const listeString = JSON.stringify(listeSelected);
		await addCampagna(
			username,
			token,
			nome,
			listeString,
			selectedUser,
			predefinitiString,
			welcomeMessage,
			obiettivi,
			prompt
		);
		const { history } = props;
		history.push("/campagne");
	};

	const handleChange = event => {
		const {
			target: { value },
		} = event;
		setListeSelected([...value]);
	};

	const handleAddPredefinito = async e => {
		let newP = e;
		const arr = predefiniti;
		arr.push(newP);
		setPredefiniti(arr);
	};

	const onDeletePredefinito = async e => {
		const arr = predefiniti.filter(p => p.sequenza !== e);
		setPredefiniti(arr);
	};

	const handleDragStart = index => {
		setDragIndex(index);
	};

	const handleDragOver = e => {
		e.preventDefault();
	};

	const handleDrop = index => {
		const newObiettivi = [...obiettivi];
		const draggedObiettivo = newObiettivi[dragIndex];
		newObiettivi.splice(dragIndex, 1);
		newObiettivi.splice(index, 0, draggedObiettivo);
		handleOrdine(newObiettivi);
	};

	const handleOrdine = objs => {
		const newObiettivi = [...objs];
		newObiettivi.map((o, i) => {
			o.ordine = i + 1;
			return o;
		});
		setObiettivi(newObiettivi);
	};

	if (loading) {
		return (
			<HeBLayout>
				<div className="d-flex justify-content-center vh-100">
					<ReactLoading
						type={"bubbles"}
						color={"orange"}
						height={"10%"}
						width={"10%"}
						className="align-self-center "
					/>
				</div>
			</HeBLayout>
		);
	}
	return (
		<HeBLayout>
			<div
				style={{
					width: "90%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					display: "flex",
					justifyContent: "space-around",
				}}
			>
				<div style={{ marginTop: 50, width: "75%" }}>
					<Grid style={{ marginBottom: 50 }} item xs={6}>
						<Box>
							<TextField
								label="Nome Campagna"
								variant="standard"
								value={nome}
								onChange={e => setNome(e.target.value)}
								fullWidth
							/>
						</Box>
					</Grid>
					<Grid style={{ marginBottom: 50 }} item xs={6}>
						<Box>
							<InputLabel style={{ marginBottom: 10 }} id="demo-multiple-chip-label">
								Seleziona lista
							</InputLabel>
							<FormControl sx={{ m: 1, width: 300 }}>
								<Select
									sx={{ width: 500, backgroundColor: "rgba(255,255,255,0.4)" }}
									labelId="demo-multiple-chip-label"
									id="demo-multiple-chip"
									multiple
									value={listeSelected}
									onChange={handleChange}
									input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
									renderValue={selected => (
										<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
											{selected.map(value => {
												const opt = listeOptions.find(o => o.lista_id == value);
												if (opt)
													return (
														<Chip
															style={{ cursor: "pointer" }}
															key={value}
															label={opt.name}
														/>
													);
											})}
										</Box>
									)}
									MenuProps={MenuProps}
								>
									{listeOptions.map(option => (
										<MenuItem
											disabled={option.campagna_id !== null}
											key={option.lista_id}
											value={parseInt(option.lista_id)}
										>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Grid>
					<Grid style={{ marginBottom: 20 }} item xs={7}>
						<Box sx={{ display: "flex" }}>
							<Checkbox
								checked={welcomeMessage}
								onChange={e => setWelcomeMessage(!welcomeMessage)}
								inputProps={{ "aria-label": "controlled" }}
							/>
							<InputLabel style={{ marginTop: 15, color: "black" }} id="demo-multiple-chip-label">
								Messaggio di Benvenuto
							</InputLabel>
						</Box>
					</Grid>
				</div>
				<Preferiti
					selectedUser={id}
					predefiniti={predefiniti}
					onAddPredefinito={handleAddPredefinito}
					onDeletePredefinito={onDeletePredefinito}
				/>
			</div>
			<Stack direction="column" spacing={2} width={"100%"} px={10} mb={5}>
				<TextField
					fullWidth
					label="Prompt"
					name="prompt"
					value={prompt}
					variant="outlined"
					onChange={e => setPrompt(e.target.value)}
					InputLabelProps={prompt && { shrink: true }}
					multiline
					rows={5}
				/>
				<Stack direction="row" spacing={2} sx={{ mt: 1 }}>
					{promptChoices.map((choice, index) => (
						<Button key={index} variant="contained" onClick={() => setPrompt(prompt + " " + choice)}>
							{choice.replace("[[", "").replace("]]", "")}
						</Button>
					))}
				</Stack>
			</Stack>
			<Stack direction="column" spacing={2} width={"100%"} px={10}>
				<Box boxShadow={10} sx={{ display: "flex", flexDirection: "column", width: "100%", p: 2 }}>
					<Typography variant="h6" sx={{ display: "flex" }}>
						Obiettivi della campagna
						<SvgIcon
							sx={{ ml: 1, cursor: "pointer" }}
							onClick={() => setObiettivi([...obiettivi, { ...obiettivo, ordine: obiettivi.length + 1 }])}
						>
							<AddIcon />
						</SvgIcon>
					</Typography>
					<Stack direction="column" spacing={2} sx={{ mt: 1 }}>
						{obiettivi &&
							obiettivi.length > 0 &&
							obiettivi.map((obiettivo, index) => (
								<Stack
									padding={2}
									direction="row"
									spacing={2}
									key={index}
									sx={{ alignItems: "center" }}
									draggable
									onDragStart={() => handleDragStart(index)}
									onDragOver={handleDragOver}
									onDrop={() => handleDrop(index)}
								>
									<SvgIcon sx={{ cursor: "grab" }}>
										<DragIcon />
									</SvgIcon>
									<TextField
										select
										fullWidth
										label="Obiettivo"
										name="obiettivo"
										value={obiettivo.obiettivo}
										variant="outlined"
										onChange={e => {
											const newObiettivi = [...obiettivi];
											newObiettivi[index].obiettivo = e.target.value;
											setObiettivi(newObiettivi);
										}}
										InputLabelProps={obiettivo.obiettivo && { shrink: true }}
									>
										{obiettiviDefault.map(option => (
											<MenuItem key={option} value={option}>
												{option}
											</MenuItem>
										))}
									</TextField>
									<Box sx={{ display: "flex" }}>
										<Checkbox
											checked={obiettivo.force}
											onChange={e => {
												const newObiettivi = [...obiettivi];
												newObiettivi[index].force = e.target.checked ? 1 : 0;
												setObiettivi(newObiettivi);
											}}
											inputProps={{ "aria-label": "controlled" }}
										/>
										<InputLabel
											style={{ marginTop: 15, color: "black" }}
											id="demo-multiple-chip-label"
										>
											Obbligatorio
										</InputLabel>
									</Box>
									<TextField
										fullWidth
										label="Dati aggiuntivi"
										name="dati_aggiuntivi"
										value={obiettivo.dati_aggiuntivi}
										variant="outlined"
										onChange={e => {
											const newObiettivi = [...obiettivi];
											newObiettivi[index].dati_aggiuntivi = e.target.value;
											setObiettivi(newObiettivi);
										}}
										InputLabelProps={obiettivo.dati_aggiuntivi && { shrink: true }}
									/>
									<Stack direction="row">
										<Checkbox
											checked={obiettivo.forzato}
											onChange={e => {
												const newObiettivi = [...obiettivi];
												newObiettivi[index].forzato = e.target.checked ? 1 : 0;
												setObiettivi(newObiettivi);
											}}
										/>
										<Typography>Forzato</Typography>
									</Stack>
									<TextField
										fullWidth
										label="Contesto"
										name="context"
										value={obiettivo.context}
										variant="outlined"
										onChange={e => {
											const newObiettivi = [...obiettivi];
											newObiettivi[index].context = e.target.value;
											setObiettivi(newObiettivi);
										}}
										InputLabelProps={obiettivo.context && { shrink: true }}
										multiline
										rows={3}
									/>
									<SvgIcon
										sx={{ mt: 2, cursor: "pointer" }}
										onClick={() => {
											const newObiettivi = [...obiettivi];
											newObiettivi.splice(index, 1);
											handleOrdine(newObiettivi);
										}}
									>
										<Trash />
									</SvgIcon>
								</Stack>
							))}
					</Stack>
				</Box>
			</Stack>
			<Button
				style={{ marginTop: 50, marginLeft: 80 }}
				variant="contained"
				className="myButton"
				onClick={handleAddCampagna}
			>
				Salva
			</Button>
		</HeBLayout>
	);
};

export default CampagneForm;
