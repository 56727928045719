import React, { useState, useEffect, useRef, useContext } from "react";
import Cookies from "universal-cookie";
import ChatListClienti from "../components/ChatListClienti";
import UserChat from "../components/UserChat";
import getChatList from "../services/getChatList";
import getTipologiaRicerca from "../services/getTipologiaRicerca";
import "./style.css";
import HeBLayout from "../components/HeBLayout";
import NotifyContext from "../context/NotifyContext";

const cookies = new Cookies();
const username = cookies.get("username", { path: "/" });
const token = cookies.get("token", { path: "/" });
const qs = require("querystring");
const id = cookies.get("id", { path: "/" });

function Chat() {
	const chatRes = useRef();
	const listRes = useRef();

	const { selectedUser } = useContext(NotifyContext);

	const [user, setUser] = useState(undefined);
	const [tipologieRicerca, setTipologieRicerca] = React.useState([]);
	const [tipologiaRicerca, setTipologiaRicerca] = React.useState({
		id: 0,
		tag: "",
	});

	const getAndSetTipologieRicerca = async (username, token) => {
		try {
			const response = await getTipologiaRicerca(username, token);
			if (response.result === "ok") {
				setTipologieRicerca(response.data);
			}
		} catch (error) {
			console.error("Errore durante il recupero delle tipologie di ricerca:", error);
		}
	};

	useEffect(() => {
		getAndSetTipologieRicerca(username, token);
	}, []);

	useEffect(() => {
		if (tipologieRicerca.length > 0) {
			const firstTagId = tipologieRicerca[0].id;
			const firstTag = tipologieRicerca[0].tag;
			setTipologiaRicerca({
				id: firstTagId,
				tag: firstTag,
			});
		}
	}, [tipologieRicerca]);

	useEffect(() => {
		const getChats = async () => {
			const parsed = qs.parse(window.location.hash);
			const mode = parsed["it/chat?mode"];

			if (!mode) return;
			if (mode !== "select") return;

			let chat = parsed["user"];
			if (!chat) return;

			let chatList = await getChatList(username, token, tipologiaRicerca.id, this.state.user);
			chat = chatList.find(c => c.destinatario === chat);

			if (!chat) return;

			setUser(chat);

			if (chatRes.current) {
				chatRes.current.loadChat(chat);
			}
		};
		getChats();
	}, [tipologiaRicerca]);

	const handleSetTipologiaRicerca = (id, tag) => {
		setTipologiaRicerca({
			id: id,
			tag: tag,
		});
	};

	return (
		<HeBLayout>
			<div className="chat_container glass_background">
				<ChatListClienti
					tipologiaRicerca={tipologiaRicerca}
					tipologieRicerca={tipologieRicerca}
					handleSetTipologiaRicerca={handleSetTipologiaRicerca}
					ref={listRes}
					onChatSelect={element => {
						setUser(element);
						chatRes.current.loadChat(element);
					}}
					selectedUser={selectedUser}
				/>
				<UserChat
					ref={chatRes}
					user={user}
					onSend={() => {
						listRes.current.reload();
					}}
					user_id={selectedUser}
				/>
			</div>
		</HeBLayout>
	);
}

export default Chat;
