import React from "react";
import imgUser from "../images/user.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Cookies from "universal-cookie";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import AggiornamentoModal from "./admin/AggiornamentoModal";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Badge } from "@mui/material";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";
import UpdateTelefonoModal from "../widgets/UpdateTelefonoModal";
import NoteRicercaModal from "../widgets/NoteRicercaModal";
import getFullChat from "../services/getFullChat";
import getAccount from "../services/getAccount";
import getTelefono from "../services/getTelefono";
import deleteChat from "../services/deleteChat";
import deleteMessaggio from "../services/deleteMessaggio";
import setChatToRead from "../services/setChatToRead";

const cookie = new Cookies();
const username = cookie.get("username");
const token = cookie.get("token");

export default class UserChat extends React.Component {
	constructor(props) {
		super(props);
		this.messageRes = React.createRef();
		this.state = {
			loading: false.valueOf,
			message: "",
			file: undefined,
			alertName: false,
			alertFile: false,
			chat: [],
			nome: "",
			modalUpdate: {
				show: false,
				id: undefined,
			},
			modalUpdateTelefono: {
				show: false,
				id: undefined,
				path: "",
			},
			modalNote: {
				show: false,
				id: undefined,
			},
		};
	}

	cleanString(inputString) {
		const nbspRegex = /\u00A0/g;
		const cleanedString = inputString.replace(nbspRegex, " ");
		return cleanedString;
	}

	async loadChat(user) {
		this.setState({ loading: true, chat: [], message: "" });
		if (user && user.destinatario !== undefined) {
			let res = await getFullChat(username, token, user.destinatario, this.props.user_id);
			getAccount(username, token, res[0].account).then(r => {
				this.setState({ nome: r ? r.nome_completo : "Team H&B" });
			});
			this.setState({ chat: res, loading: false });
			setTimeout(() => {
				this.messageRes.current.scrollIntoView({ behavior: "smooth" });
			}, 100);
		}
	}

	cancel = () => {
		let modalUpdate = this.state.modalUpdate;
		modalUpdate.show = false;
		let modalUpdateTelefono = this.state.modalUpdateTelefono;
		modalUpdateTelefono.show = false;
		let modalNote = this.state.modalNote;
		modalNote.show = false;
		this.setState({ modalUpdate, modalUpdateTelefono, modalNote });
	};

	addText(text) {
		if (this.props.user !== undefined) {
			let replace = text.replace(/%%nome%%/g, this.props.user.nome).replace(/%%user%%/g, this.state.nome);
			if (replace !== this.state.message) {
				this.setState({
					message: this.state.message + (this.state.message ? " " : "") + replace,
				});
			}
		}
	}

	getMessageClean(text) {
		return text.replace(/(\?\?\?\?)/g, "(emoticon)");
	}

	render() {
		if (this.props.user === undefined || this.state.loading) {
			return <div className="h-100 position-relative userChat"></div>;
		}
		return (
			<div className="h-100 position-relative userChat">
				{this.state.modalUpdate.show && (
					<AggiornamentoModal
						onReload={() => this.props.onSend()}
						modal={this.state.modalUpdate}
						onCancel={this.cancel}
					/>
				)}
				{this.state.modalUpdateTelefono.show && (
					<UpdateTelefonoModal
						telefono={this.props.user.telefono}
						modal={this.state.modalUpdateTelefono}
						onCancel={this.cancel}
					/>
				)}
				{this.state.modalNote.show && (
					<NoteRicercaModal
						note={this.props.user.note_ricerca}
						modal={this.state.modalNote}
						onCancel={this.cancel}
					/>
				)}
				<div className="d-flex" style={{ height: "calc(100% - 161px)" }}>
					<div className="w-100">
						{/* INTESTAZIONE CHAT */}
						<div className="p-3" style={{ height: 80, zIndez: 9999 }}>
							<div className="d-flex align-items-center">
								<div className=" d-flex align-items-center w-100">
									<div className="d-flex flex-row align-items-center">
										<div
											className="bg-light rounded-circle p-1 me-3 align-self-center overflow-hidden"
											style={{ borderRadius: 50, width: 41 }}
										>
											<img
												src={imgUser}
												alt="img user"
												style={{
													width: "2rem",
													height: "2.3rem",
													textAlign: "center",
													marginBottom: -6,
													marginLeft: 1,
												}}
											/>
										</div>
										<div class="flex flex-column">
											<p className="text-white m-0 fs-6">
												{this.props.user.nome + " " + this.props.user.cognome}
											</p>
											{this.props.user.account !== "" && (
												<p className="text-light m-0" style={{ fontSize: 12 }}>
													{this.props.user.account}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="justify-self-end me-3 flex-row d-flex">
									<DropdownButton
										variant="standard"
										menuVariant="dark"
										className="plus_button"
										title={<FontAwesomeIcon icon={faCircleInfo} size="2xl" />}
										onClick={() => {
											getTelefono(username, token, this.props.user.id).then(res =>
												this.setState({ telefono: res.telefono })
											);
										}}
									>
										{this.props.user.job_title !== "" && (
											<Dropdown.Item>
												<p className="text-light m-0" style={{ fontSize: 12 }}>
													Job Title: {this.props.user.job_title}
												</p>
											</Dropdown.Item>
										)}
										{this.props.user.telefono !== null && (
											<Dropdown.Item
												onClick={() => {
													let path = window.location.href.split("/")[3];
													let x = {
														show: true,
														id: this.props.user.id,
														path: path,
														telefono: this.state.telefono,
													};
													this.setState({ modalUpdateTelefono: x });
												}}
											>
												<p className="text-light p-0 m-0" style={{ fontSize: 12 }}>
													Tel: {this.state.telefono}
												</p>
											</Dropdown.Item>
										)}
										{
											<Dropdown.Item>
												<p
													className="text-light m-0"
													style={{ fontSize: 12 }}
													onClick={() => {
														let x = {
															show: true,
															id: this.props.user.id,
														};
														this.setState({ modalNote: x });
													}}
												>
													Note Ricerca:
												</p>
											</Dropdown.Item>
										}
									</DropdownButton>
									<button
										style={{
											marginRight: 15,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: 0,
										}}
									>
										<Badge
											invisible={this.props.user.count === 0 ? true : false}
											badgeContent={this.props.user.count}
											color="primary"
										>
											<span
												className="d-inline-block"
												tabIndex="0"
												data-toggle="tooltip"
												title="Commenti"
											>
												<ChatOutlinedIcon
													style={{ color: "white", fontSize: 30 }}
													onClick={() => {
														let x = {
															show: true,
															id: this.props.user.id,
														};
														this.setState({ modalUpdate: x });
													}}
												/>
											</span>
										</Badge>
									</button>
									<button
										style={{
											marginRight: 25,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: 0,
										}}
										onClick={() => {
											window.open(this.props.user.destinatario, "_blank");
										}}
									>
										<span
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Visita profilo Linkedin"
										>
											<FontAwesomeIcon
												icon={faLinkedinIn}
												style={{ color: "white", fontSize: 30 }}
											/>
										</span>
									</button>
									<button
										style={{
											marginRight: 25,
											marginTop: 5,
											cursor: "pointer",
											backgroundColor: "transparent",
											border: 0,
										}}
										onClick={() => {
											let lastMessage = [...this.state.chat].pop();
											setChatToRead(username, token, lastMessage.id).then(res => {
												if (res.result === "ok") {
													this.props.onSend();
												}
											});
										}}
									>
										<span
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Segna da leggere"
										>
											<FontAwesomeIcon
												icon={faEnvelope}
												style={{ color: "white", fontSize: 25 }}
											/>
										</span>
									</button>
									<button
										style={{
											cursor: "pointer",
											backgroundColor: "transparent",
											border: 0,
										}}
										className="bin"
										onClick={() => {
											if (window.confirm("Sei sicuro di voler eliminare la chat ?")) {
												deleteChat(
													username,
													token,
													this.props.user.destinatario,
													this.props.user.account
												).then(res => {
													if (res.result === "ok") {
														this.loadChat(undefined);
														this.props.onSend();
													}
												});
											}
										}}
									>
										<span
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Elimina"
										>
											<FontAwesomeIcon
												icon={faTrashCan}
												style={{ color: "white", fontSize: 23 }}
											/>
										</span>
									</button>
								</div>
							</div>
						</div>

						{/* CHAT */}

						<div className="chat p-4 d-flex flex-column h-100">
							{this.state.chat.length !== 0 ? (
								this.state.chat.map((el, ind) => {
									return (
										<div
											ref={ind === this.state.chat.length - 1 ? this.messageRes : null}
											key={Math.random()}
											className={el.ricevuto === 0 ? "sent" : "received"}
											style={{
												backgroundColor: el.errore === "1" ? "#c7631c" : "",
											}}
										>
											{el.inviato_bot === "0" && (
												<div style={{ textAlign: "end" }}>
													<FontAwesomeIcon
														icon={faCircleXmark}
														style={{
															color: "white",
															fontSize: 12,
															marginRight: 5,
															cursor: "pointer",
														}}
														onClick={async () => {
															if (
																window.confirm(
																	"Sei sicuro di voler eliminare il messaggio ?"
																)
															) {
																await deleteMessaggio(username, token, el.id);
																this.loadChat(this.props.user);
															}
														}}
													/>
												</div>
											)}
											<div>
												<p className="px-3 pt-1 m-0">{this.getMessageClean(el.messaggio)}</p>
											</div>
											<div>
												<p
													className={el.ricevuto === "1" ? "pb-1" : ""}
													style={{
														fontSize: 13,
														textAlign: "end",
														margin: 0,
														paddingLeft: "1rem",
														paddingRight: "1rem",
													}}
												>
													{el.errore === "1" && (
														<FontAwesomeIcon
															icon={faExclamation}
															style={{
																color: "black",
																fontSize: 16,
																marginRight: 5,
																marginLeft: 5,
															}}
														/>
													)}
													{el.data_ora}
												</p>
												{el.ricevuto === "0" && (
													<p
														style={{ fontSize: 13 }}
														className="px-3 pb-1 m-0 text-end text-capitalize"
													>
														{el.inviato_da}
													</p>
												)}
											</div>
										</div>
									);
								})
							) : (
								<div className="d-flex justify-content-center text-light" style={{ marginTop: 250 }}>
									<h1>inizia nuova chat</h1>
								</div>
							)}
						</div>
						{/* INPUT MESSAGGIO */}
						{/*
						<div
							className="p-3 mb-0"
							style={{
								position: "absolute",
								bottom: 0,
								right: 0,
								left: 0,
								height: 90,
							}}
						>
							<div className="row flex align-items-center">
								<div className="col">
									<div
										style={{
											paddingTop: 8,
											paddingBottom: 8,
											paddingLeft: 16,
											paddingRight: 16,
											borderRadius: 10,
											backgroundColor: "#36404A",
											border: 0,
											color: "white",
											width: "100%",
										}}
									>
										<textarea
											id="textarea"
											value={this.state.message}
											autoFocus
											onKeyDown={e => {
												if (e.key === "Enter" && e.shiftKey) {
													let message = this.state.message + "\r\n";
													this.setState({ message });
												} else if (e.key === "Enter" && !e.shiftKey) {
													if (this.state.message.trim() !== "") {
														sendMessageSite(
															username,
															token,
															this.props.user.destinatario,
															this.props.user.nome,
															this.props.user.cognome,
															this.props.user.account,
															this.cleanString(this.state.message.trim())
														).then(el => {
															this.loadChat(this.props.user);
															this.props.onSend();
															this.setState({ message: "" });
														});
													}
													e.preventDefault();
												}
											}}
											type="text"
											style={{
												paddingTop: 5,
												paddingBottom: 8,
												paddingLeft: 16,
												paddingRight: 16,
												borderRadius: 10,
												backgroundColor: "#36404A",
												border: 0,
												color: "white",
												outline: "none",
												width: "100%",
												minHeight: 35,
												maxHeight: 35,
												overflow: "auto",
												resize: "none",
											}}
											placeholder="Scrivi messaggio..."
											onChange={event => {
												this.setState({ message: event.target.value });
											}}
										/>
									</div>
								</div>
								<div className="col-auto ps-0">
									<Button
										variant=""
										style={{ margin: 10 }}
										onClick={() => {
											if (this.state.message.trim() !== "") {
												sendMessageSite(
													username,
													token,
													this.props.user.destinatario,
													this.props.user.nome,
													this.props.user.cognome,
													this.props.user.account,
													this.cleanString(this.state.message.trim())
												).then(el => {
													this.loadChat(this.props.user);
													this.props.onSend();
													this.setState({ message: "" });
												});
											}
										}}
									>
										<div
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Invia"
										>
											<FontAwesomeIcon
												icon={faPaperPlane}
												style={{ color: "#fff", fontSize: 25 }}
											/>
										</div>
									</Button>
								</div>
							</div>
						</div>
						*/}
					</div>
				</div>
			</div>
		);
	}
}
