import React, { useState, useEffect, useContext } from "react";

import Cookies from "universal-cookie";

import getUtenteDashboard from "../services/getUtenteDashboard";

import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

import HeBLayout from "../components/HeBLayout";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import NotifyContext from "../context/NotifyContext";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
const type = cookie.get("tipo", { path: "/" });

const Dashboard = () => {
	const { selectedUser } = useContext(NotifyContext);

	const [connessioni, setConnessioni] = useState([]);
	const [messaggi, setMessaggi] = useState([]);
	const [emails, setEmails] = useState([]);
	const [pageViews, setPageViews] = useState([]);
	const [lastContacts, setLastContacts] = useState([]);
	const [lastEmails, setLastEmails] = useState([]);
	const [pieChartData, setPieChartData] = useState([]);
	const [selectedFromDate, setSelectedFromDate] = useState(new Date());
	const [selectedToDate, setSelectedToDate] = useState(new Date());
	const [pieChartDataMessage, setPieChartDataMessage] = useState("");
	const [selectedFromDateMessage, setSelectedFromDateMessage] = useState(new Date());
	const [selectedToDateMessage, setSelectedToDateMessage] = useState(new Date());

	useEffect(() => {
		const getDashboard = async () => {
			const selectedFromDateFormatted = formatDate(selectedFromDate);
			const selectedToDateFormatted = formatDate(selectedToDate);
			const selectedFromDateMessageFormatted = formatDate(selectedFromDateMessage);
			const selectedToDateMessageFormatted = formatDate(selectedToDateMessage);

			const res = await getUtenteDashboard(
				username,
				token,
				selectedUser,
				selectedFromDateFormatted,
				selectedToDateFormatted,
				selectedFromDateMessageFormatted,
				selectedToDateMessageFormatted
			);
			if (res.ok) {
				res.connessioni ? setConnessioni(res.connessioni) : setConnessioni([]);
				res.last_contacts ? setLastContacts(res.last_contacts) : setLastContacts([]);
				res.messaggi ? setMessaggi(res.messaggi) : setMessaggi([]);
				res.emails ? setEmails(res.emails) : setEmails([]);
				res.page_views ? setPageViews(res.page_views) : setPageViews([]);
				res.last_emails ? setLastEmails(res.last_emails) : setLastEmails([]);
				res.rangeContactsForAccount ? setPieChartData(res.rangeContactsForAccount) : setPieChartData([]);
				res.rangeMessagesForAccount
					? setPieChartDataMessage(res.rangeMessagesForAccount)
					: setPieChartDataMessage("");
			} else {
				console.log(res);
			}
		};
		getDashboard();

		const intervalId = setInterval(() => {
			getDashboard();
		}, 30000);

		return () => clearInterval(intervalId);
	}, [selectedUser, selectedFromDate, selectedToDate, selectedFromDateMessage, selectedToDateMessage]);

	const handleDateChange = (fromDate, toDate) => {
		setSelectedFromDate(fromDate);
		setSelectedToDate(toDate);
	};

	const handleDateChangeMessage = (fromDate, toDate) => {
		setSelectedFromDateMessage(fromDate);
		setSelectedToDateMessage(toDate);
	};

	const formatDate = date => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	return (
		<HeBLayout>
			<div style={{ width: "92%", marginInline: "auto", marginBottom: "20px" }}>
				<div>
					<Grid container spacing={3} marginY={1}>
						<Grid item xs={6}>
							<Box className="pt-5 pe-5 border-radius-16 h-100  bg-white shadow">
								{connessioni.length > 0 && (
									<ResponsiveContainer width="100%" height={320}>
										<BarChart data={connessioni}>
											<XAxis dataKey="date" />
											<YAxis dataKey="connessioni" />
											<Tooltip />
											<Legend />
											<Bar dataKey="connessioni" fill="#120747" />
										</BarChart>
									</ResponsiveContainer>
								)}
							</Box>
						</Grid>
						{
							type !== "3" && (
								<>
									<Grid item xs={6}>
										<Box className="pt-5 pe-5 border-radius-16 h-100  bg-white shadow">
											{messaggi.length > 0 && (
												<ResponsiveContainer width="100%" height={320}>
													<BarChart data={messaggi}>
														<XAxis dataKey="date" />
														<YAxis dataKey="messaggi" />
														<Tooltip />
														<Legend />
														<Bar dataKey="messaggi" fill="#120747" />
													</BarChart>
												</ResponsiveContainer>
											)}
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box className="pt-5 pe-5 border-radius-16 h-100  bg-white shadow">
											{emails.length > 0 && (
												<ResponsiveContainer width="100%" height={320}>
													<BarChart data={emails}>
														<XAxis dataKey="date" />
														<YAxis dataKey="emails" />
														<Tooltip />
														<Legend />
														<Bar dataKey="emails" fill="#120747" />
													</BarChart>
												</ResponsiveContainer>
											)}
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box className="pt-5 pe-5 border-radius-16 h-100  bg-white shadow">
											{pageViews.length > 0 && (
												<ResponsiveContainer width="100%" height={320}>
													<BarChart data={pageViews}>
														<XAxis dataKey="date" />
														<YAxis dataKey="pageViews" />
														<Tooltip />
														<Legend />
														<Bar dataKey="pageViews" fill="#120747" />
													</BarChart>
												</ResponsiveContainer>
											)}
										</Box>
									</Grid>
								</>
							)
						} 
						<Grid item xs={6}>
							<Box className="pt-5 border-radius-16 h-100 bg-white shadow position-relative">
								<Box className="d-flex justify-content-end align-items-center">
									<TextField
										className="me-3"
										label="Data Inizio"
										type="date"
										value={selectedFromDate.toISOString().split("T")[0]}
										onChange={e => handleDateChange(new Date(e.target.value), selectedToDate)}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											max: new Date().toISOString().split("T")[0],
										}}
									/>
									<TextField
										className="me-3"
										label="Data Fine"
										type="date"
										value={selectedToDate.toISOString().split("T")[0]}
										onChange={e => handleDateChange(selectedFromDate, new Date(e.target.value))}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											max: new Date().toISOString().split("T")[0],
										}}
									/>
								</Box>
								<Box className="d-flex justify-content-end align-items-center px-3">
									<span
										className="ms-2 cursor_pointer"
										onClick={() => handleDateChange(new Date(), new Date())}
										style={
											selectedFromDate.toISOString().split("T")[0] ===
												new Date().toISOString().split("T")[0] &&
											selectedToDate.toISOString().split("T")[0] ===
												new Date().toISOString().split("T")[0]
												? { textDecoration: "underline", fontWeight: "bold" }
												: {}
										}
									>
										Oggi
									</span>
									<span
										className="ms-2 cursor_pointer"
										onClick={() =>
											handleDateChange(
												new Date(new Date().setDate(new Date().getDate() - 1)),
												new Date(new Date().setDate(new Date().getDate() - 1))
											)
										}
										style={
											selectedFromDate.toISOString().split("T")[0] ===
												new Date(new Date().setDate(new Date().getDate() - 1))
													.toISOString()
													.split("T")[0] &&
											selectedToDate.toISOString().split("T")[0] ===
												new Date(new Date().setDate(new Date().getDate() - 1))
													.toISOString()
													.split("T")[0]
												? { textDecoration: "underline", fontWeight: "bold" }
												: {}
										}
									>
										Ieri
									</span>
									<span
										className="ms-2 cursor_pointer"
										onClick={() =>
											handleDateChange(
												new Date(new Date().setDate(new Date().getDate() - 7)),
												new Date()
											)
										}
										style={
											selectedFromDate.toISOString().split("T")[0] ===
												new Date(new Date().setDate(new Date().getDate() - 7))
													.toISOString()
													.split("T")[0] &&
											selectedToDate.toISOString().split("T")[0] ===
												new Date().toISOString().split("T")[0]
												? { textDecoration: "underline", fontWeight: "bold" }
												: {}
										}
									>
										Ultima Settimana
									</span>
									<span
										className="ms-2 cursor_pointer"
										onClick={() =>
											handleDateChange(
												new Date(new Date().setDate(new Date().getDate() - 30)),
												new Date()
											)
										}
										style={
											selectedFromDate.toISOString().split("T")[0] ===
												new Date(new Date().setDate(new Date().getDate() - 30))
													.toISOString()
													.split("T")[0] &&
											selectedToDate.toISOString().split("T")[0] ===
												new Date().toISOString().split("T")[0]
												? { textDecoration: "underline", fontWeight: "bold" }
												: {}
										}
									>
										Ultimo Mese
									</span>
								</Box>
								{pieChartData.some(item => item.total > 0) ? null : (
									<Typography variant="h6" className="text-center w-100 mt-5 mb-5">
										Nessun contatto per questo arco temporale
									</Typography>
								)}
								{pieChartData.length > 0 && (
									<ResponsiveContainer width="100%" height={320}>
										<PieChart>
											<Pie
												data={pieChartData}
												cx="50%"
												cy="50%"
												outerRadius={80}
												fill="#8884d8"
												dataKey="total"
											>
												{pieChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={entry.color} />
												))}
											</Pie>
											<Tooltip
												formatter={(value, name) => [
													pieChartData[name].total +
														(pieChartData[name].total > 1 ? " contatti" : " contatto"),
													pieChartData[name].account,
												]}
											/>
											<Legend
												payload={pieChartData.map((entry, index) => ({
													value: entry.nome,
													type: "square",
													color: entry.color,
												}))}
											/>
										</PieChart>
									</ResponsiveContainer>
								)}
							</Box>
						</Grid>
						{
							type !== "3" && (
								<Grid item xs={6}>
									<Box className="pt-5 border-radius-16 h-100 bg-white shadow position-relative">
										<Box className="d-flex justify-content-end align-items-center">
											<TextField
												className="me-3"
												label="Data Inizio"
												type="date"
												value={selectedFromDateMessage.toISOString().split("T")[0]}
												onClick={() => handleDateChangeMessage(new Date(), new Date())}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													max: new Date().toISOString().split("T")[0],
												}}
											/>
											<TextField
												className="me-3"
												label="Data Fine"
												type="date"
												value={selectedToDateMessage.toISOString().split("T")[0]}
												onClick={() => handleDateChangeMessage(new Date(), new Date())}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													max: new Date().toISOString().split("T")[0],
												}}
											/>
										</Box>
										<Box className="d-flex justify-content-end align-items-center px-3">
											<span
												className="ms-2 cursor_pointer"
												onClick={() => handleDateChangeMessage(new Date(), new Date())}
												style={
													selectedFromDateMessage.toISOString().split("T")[0] ===
														new Date().toISOString().split("T")[0] &&
													selectedToDateMessage.toISOString().split("T")[0] ===
														new Date().toISOString().split("T")[0]
														? { textDecoration: "underline", fontWeight: "bold" }
														: {}
												}
											>
												Oggi
											</span>
											<span
												className="ms-2 cursor_pointer"
												onClick={() =>
													handleDateChangeMessage(
														new Date(new Date().setDate(new Date().getDate() - 1)),
														new Date(new Date().setDate(new Date().getDate() - 1))
													)
												}
												style={
													selectedFromDateMessage.toISOString().split("T")[0] ===
														new Date(new Date().setDate(new Date().getDate() - 1))
															.toISOString()
															.split("T")[0] &&
													selectedToDateMessage.toISOString().split("T")[0] ===
														new Date(new Date().setDate(new Date().getDate() - 1))
															.toISOString()
															.split("T")[0]
														? { textDecoration: "underline", fontWeight: "bold" }
														: {}
												}
											>
												Ieri
											</span>
											<span
												className="ms-2 cursor_pointer"
												onClick={() =>
													handleDateChangeMessage(
														new Date(new Date().setDate(new Date().getDate() - 7)),
														new Date()
													)
												}
												style={
													selectedFromDateMessage.toISOString().split("T")[0] ===
														new Date(new Date().setDate(new Date().getDate() - 7))
															.toISOString()
															.split("T")[0] &&
													selectedToDateMessage.toISOString().split("T")[0] ===
														new Date().toISOString().split("T")[0]
														? { textDecoration: "underline", fontWeight: "bold" }
														: {}
												}
											>
												Ultima Settimana
											</span>
											<span
												className="ms-2 cursor_pointer"
												onClick={() =>
													handleDateChangeMessage(
														new Date(new Date().setDate(new Date().getDate() - 30)),
														new Date()
													)
												}
												style={
													selectedFromDateMessage.toISOString().split("T")[0] ===
														new Date(new Date().setDate(new Date().getDate() - 30))
															.toISOString()
															.split("T")[0] &&
													selectedToDateMessage.toISOString().split("T")[0] ===
														new Date().toISOString().split("T")[0]
														? { textDecoration: "underline", fontWeight: "bold" }
														: {}
												}
											>
												Ultimo Mese
											</span>
										</Box>
										{pieChartDataMessage.length === 0 ? (
											<Typography variant="h6" className="text-center w-100 mt-5 mb-5">
												Nessun messaggio per questo arco temporale
											</Typography>
										) : (
											<ResponsiveContainer width="100%" height={320}>
												<PieChart>
													<Pie
														data={pieChartDataMessage}
														cx="50%"
														cy="50%"
														outerRadius={80}
														fill="#8884d8"
														dataKey="total"
													>
														{pieChartDataMessage.map((entry, index) => (
															<Cell key={`cell-${index}`} fill={entry.color} />
														))}
													</Pie>
													<Tooltip
														formatter={(value, name) => [
															pieChartDataMessage[name].total +
																(pieChartDataMessage[name].total > 1
																	? " messaggi"
																	: " messaggio"),
															pieChartDataMessage[name].account,
														]}
													/>
													<Legend
														payload={pieChartDataMessage.map((entry, index) => ({
															value: entry.nome,
															type: "square",
															color: entry.color,
														}))}
													/>
												</PieChart>
											</ResponsiveContainer>
										)}
									</Box>
								</Grid>
							)
						}
						
						<Grid item xs={6}>
							<Box className="p-3 border-radius-16 h-100 bg-white shadow position-relative">
								<Typography variant="h6">Ultime connessioni effettuate</Typography>
								<hr />
								<Stack className="dashboard_box">
									{lastContacts.length === 0 ? (
										<Typography marginBottom={1}>Nessuna connessione stabilita</Typography>
									) : (
										<Box>
											<TableContainer
												sx={{
													boxShadow: "none",
												}}
												component={Paper}
											>
												<Table responsive>
													<TableHead>
														<TableRow>
															<TableCell>Data</TableCell>
															<TableCell>Nome</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{lastContacts.map((row, index) => (
															<TableRow key={index}>
																{row.formatted_updated_at && (
																	<TableCell
																		component="th"
																		scope="row"
																		sx={{ padding: "5px 10px" }}
																	>
																		{row.formatted_updated_at}
																	</TableCell>
																)}
																{row.nome && (
																	<TableCell
																		component="th"
																		scope="row"
																		sx={{ padding: "5px 10px" }}
																	>
																		{row.nome}
																	</TableCell>
																)}
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</Box>
									)}
									<Box className="d-flex justify-content-end position-absolute bottom-0 end-0 py-2 px-4">
										<Link className="text-dark" to="/contatti">
											Vai alle connessioni
										</Link>
									</Box>
								</Stack>
							</Box>
						</Grid>
						{
							type !== "3" && (
								<Grid item xs={6}>
									<Box className="p-3 border-radius-16 h-100 bg-white shadow position-relative">
										<Typography variant="h6">Ultime email ottenute</Typography>
										<hr />
										<Stack className="dashboard_box">
											{lastEmails.length === 0 ? (
												<Typography marginBottom={1}>Nessuna email ottenuta</Typography>
											) : (
												<Box>
													<TableContainer
														sx={{
															boxShadow: "none",
														}}
														component={Paper}
													>
														<Table responsive>
															<TableHead>
																<TableRow>
																	<TableCell>Data</TableCell>
																	<TableCell>Nome</TableCell>
																	<TableCell>Email</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{lastEmails.map((row, index) => (
																	<TableRow key={index}>
																		{row.formatted_updated_at && (
																			<TableCell
																				component="th"
																				scope="row"
																				sx={{ padding: "5px 10px" }}
																			>
																				{row.formatted_updated_at}
																			</TableCell>
																		)}
																		{row.nome && (
																			<TableCell
																				component="th"
																				scope="row"
																				sx={{ padding: "5px 10px" }}
																			>
																				{row.nome}
																			</TableCell>
																		)}
																		{row.data && (
																			<TableCell
																				component="th"
																				scope="row"
																				sx={{ padding: "5px 10px" }}
																			>
																				{row.data}
																			</TableCell>
																		)}
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</TableContainer>
												</Box>
											)}
											<Box className="d-flex justify-content-end position-absolute bottom-0 end-0 py-2 px-4">
												<Link className="text-dark" to="/emails">
													Vai alle email
												</Link>
											</Box>
										</Stack>
									</Box>
								</Grid>
							)
						}
						
					</Grid>
				</div>
			</div>
		</HeBLayout>
	);
};

export default Dashboard;
