import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import imgBot from "../images/bot.png";
import ReactLoading from "react-loading";
import "./style.css";

export default class UserChatTest extends React.Component {
	constructor(props) {
		super(props);
		this.messageRes = React.createRef();
		this.state = {
			message: "",
			file: undefined,
			alertName: false,
			alertFile: false,
			chat: [],
			nome: "",
			modalUpdate: {
				show: false,
				id: undefined,
			},
			modalUpdateTelefono: {
				show: false,
				id: undefined,
				path: "",
			},
			modalNote: {
				show: false,
				id: undefined,
			},
		};
	}

	cleanString(inputString) {
		const nbspRegex = /\u00A0/g;
		const cleanedString = inputString.replace(nbspRegex, ' ');
		return cleanedString;
	}

	cancel = () => {
		let modalUpdate = this.state.modalUpdate;
		modalUpdate.show = false;
		let modalUpdateTelefono = this.state.modalUpdateTelefono;
		modalUpdateTelefono.show = false;
		let modalNote = this.state.modalNote;
		modalNote.show = false;
		this.setState({ modalUpdate, modalUpdateTelefono, modalNote });
	};

	render() {
		if (this.props.messages === undefined) {
			return <div className="h-100 position-relative userChat"></div>;
		}


		return (
			<div className="h-100 position-relative userChat">
				<div className="d-flex" style={{ height: "calc(100% - 161px)" }}>
					<div className="w-100">
					<div className="p-3" style={{ height: 80, zIndez: 9999 }}>
							<div className="d-flex align-items-center">
								<div className=" d-flex justify-content-between align-items-center w-100">
									<div className="d-flex flex-row align-items-center">
										<img
											src={imgBot}
											alt="img user"
											style={{ width: 50, height: 50 }}
											className="rounded-circle bg-dark me-2"
										/>
										<div class="flex flex-column">
											<p className="text-white m-0 fs-6 fw-bold">
												Ambrogio
											</p>
											<p className="text-white m-0 fs-6">
												Parla con Ambrogio, il nostro BOT di Test
											</p>
										</div>
									</div>
									<button
										style={{ cursor: "pointer", backgroundColor: "transparent", border: 0 }}
										className="bin"
										onClick={() => this.props.deleteMessage()}
									>
										<span
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Elimina"
										>
											<FontAwesomeIcon icon={faTrashCan} style={{ color: "white", fontSize: 23 }} />
										</span>
									</button>
								</div>
							</div>
						</div>
						<div className="chat p-4 d-flex flex-column h-100 w-75 m-auto">
								{this.props.messages.length > 0 && this.props.messages.map((el, ind) => {
									return (
										<div
											ref={ind === this.state.chat.length - 1 ? this.messageRes : null}
											key={Math.random()}
											className={el.ricevuto === 0 ? "sent p-2 w-100" : "received p-2 w-100"}
											style={{ backgroundColor: el.errore === 1 ? "#c7631c" : "" }}
										>
											<div>
												<p className="px-3 pt-1 m-0">{el.messaggio}</p>
											</div>
											<div>
												<p
													className={el.ricevuto === 1 ? "pb-1" : ""}
													style={{
														fontSize: 13,
														textAlign: "end",
														margin: 0,
														paddingLeft: "1rem",
														paddingRight: "1rem",
													}}
												>
													{el.errore === 1 && (
														<FontAwesomeIcon
															icon={faExclamation}
															style={{
																color: "black",
																fontSize: 16,
																marginRight: 5,
																marginLeft: 5,
															}}
														/>
													)}
													{el.data_ora}
												</p>
											</div>
										</div>
									);
								})}
								{this.props.loading && <div className="w-100 d-flex justify-content-center align-items-center">
									<ReactLoading height={20} type="cylon" color="#fff" />
								</div>}
						</div>
						{/* INPUT MESSAGGIO */}
						<div
							className="p-3 mb-0 w-75 m-auto"
							style={{
								position: "absolute",
								bottom: 0,
								right: 0,
								left: 0,
								height: 90,
							}}
						>
							<div className="row flex align-items-center">
								<div className="col">
									<div
										className="position-relative"
										style={{
											padding: 8,
											borderRadius: 10,
											marginBottom: 10,
											backgroundColor: "#36404A",
											border: 0,
											color: "white",
											width: "100%",
										}}
									>
										<textarea
											id="textarea"
											value={this.state.message}
											autoFocus
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													this.props.onSend(this.state.message);
													this.setState({ message: "" });
												}
											}}
											type="text"
											style={{
												padding: 8,
												borderRadius: 10,
												backgroundColor: "#36404A",
												border: 0,
												color: "white",
												outline: "none",
												width: "100%",
												height: 40,
												overflow: "auto",
												resize: "none",
											}}
											placeholder="Scrivi messaggio..."
											onChange={event => {
												this.setState({ message: event.target.value });
											}}
										/>
									</div>
								</div>
								<div className="col-1">
									<Button
										className="p-0"
										variant=""
										onClick={() => {
											if (this.state.message.trim() !== "") {
												this.props.onSend(this.state.message);
												this.setState({ message: "" });
											}
										}}
									>
										<div
											className="d-inline-block"
											tabIndex="0"
											data-toggle="tooltip"
											title="Invia"
										>
											<FontAwesomeIcon
												icon={faPaperPlane}
												style={{ color: "#fff", fontSize: 25 }}
											/>
										</div>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
