import React from "react";
import Cookies from "universal-cookie";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import Button from "@mui/material/Button";
import { Tooltip, TextField, Select, MenuItem } from "@material-ui/core";
import { Alert, Stack, Snackbar, Badge, Typography } from "@mui/material";
import LazyLoading from "react-list-lazy-load";
import ReactList from "react-list";

//CUSTOM COMPONENTS
import DeleteModal from "../../components/admin/DeleteModal";
import AggiornamentoModalClienti from "./AggiornamentoModalClienti";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

//API CALLS
import getAllContactsByTipologiaRicerca from "../../services/getAllContactsByTipologiaRicerca";
import getContattiStatsByTipologiaRicerca from "../../services/getContattiStatsByTipologiaRicerca";
import getAllRicercheByTipologiaRicerca from "../../services/getAllRicercheByTipologiaRicerca";
import deleteContatto from "../../services/deleteContatto";
import updateTelefonoContatto from "../../services/updateTelefonoContatto";
import updateStatoContatto from "../../services/updateStatoContatto";
import updateEmailContatto from "../../services/updateEmailContatto";
import updateAziendaContattoClienti from "../../services/updateAziendaContattoClienti";

import _ from "lodash";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const headCells = [
	{
		width: 250,
		label: "Azioni",
		id: "azioni",
	},
	{
		width: 250,
		label: "Nome",
		id: "nome",
		numeric: false,
	},
	{
		width: 250,
		label: "",
		id: "aggiornamento",
		numeric: false,
	},
	{
		width: 250,
		label: "Azienda",
		id: "azienda",
		numeric: false,
	},
	{
		width: 250,
		label: "Telefono",
		id: "telefono",
		numeric: true,
	},
	{
		width: 250,
		label: "Stato",
		id: "stato",
		numeric: true,
	},
	{
		width: 250,
		label: "Email",
		id: "email",
		numeric: true,
	},
	{
		width: 250,
		label: "Account",
		id: "account_nome_completo",
		numeric: true,
	},
	{
		width: 250,
		label: "Ultimo aggiornamento",
		id: "ultimo_aggiornamento",
		numeric: true,
	},
	{
		width: 250,
		label: "Registro creazione",
		id: "created_at",
		numeric: true,
	},
];

const statoSelect = [
	{ value: "Non specificato", color: "grey" },
	{ value: "Contattato", color: "purple" },
	{ value: "In funnel", color: "purple" },
	{ value: "Interessato", color: "purple" },
	{ value: "Non interessato", color: "purple" },
	{ value: "Call prenotata", color: "purple" },
	{ value: "Call effettuata", color: "purple" },
	{ value: "NO SHOW", color: "purple" },
];
const inputPadding = { style: { padding: 8, width: "fit-content" } };

export default class ContattiClientiTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			modalUpdate: {
				show: false,
				id: undefined,
			},
			toastAlert: {
				open: false,
				message: "",
				severity: "success",
			},
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			filter: "",
			// page: 1,
			// maxPage: 1,
			stats: {},
			filterStato: "Tutti",
			filterFollowUp: "Tutti",
			fromSelect: false,
			lastUpdated: false,
			nuovoContatto: {},
			ricerche: [],
			total: 0,
			loading: true,
			user: this.props.selectedUser,
		};
	}

	tableBodyRef = React.createRef();

	//return the number of hours from now to the date passed as parameter
	getDaysDiffFromToday = date => {
		const today = new Date();
		const dateToCompare = new Date(date);
		const diffTime = Math.abs(today - dateToCompare);
		const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffHours - 1 < 24 ? diffHours - 1 + " ore fa" : diffDays + " giorni fa";
	};

	handleApiResponse = (response, type) => {
		let toastAlert = this.state.toastAlert;
		if (response === "ok") {
			toastAlert = { open: true, message: type + " aggiornato con successo", severity: "success" };
			setTimeout(() => {
				this.setState({ toastAlert: { open: false, message: "", severity: "success" } });
			}, 3000);
		} else {
			toastAlert = { open: true, message: type + " non aggiornato", severity: "error" };
		}
		this.setState({ toastAlert });
	};

	handleFilterStato = async (stato, filter, user) => {
		this.setState({ loading: true });
		let res = await getAllContactsByTipologiaRicerca(
			username,
			token,
			this.props.tipologiaRicerca.id,
			stato,
			filter,
			user
		);
		let resStats = await getContattiStatsByTipologiaRicerca(
			username,
			token,
			this.props.tipologiaRicerca.id,
			stato,
			filter,
			user
		);
		this.setState({ rows: res.profili, total: resStats.total, loading: false });
	};

	// DELETE PROFILE
	openModal = profile => {
		let modal = this.state.modal;
		modal.show = true;
		modal.fullName = profile.nome;
		modal.id = profile.id;
		this.setState({ modal });
	};

	cancel = () => {
		let modal = this.state.modal;
		modal.show = false;
		let modalUpdate = this.state.modalUpdate;
		modalUpdate.show = false;
		this.setState({ modal, modalUpdate });
	};

	confirmedDelete = async () => {
		deleteContatto(username, token, this.state.modal.id);
		window.location.reload();
	};

	componentDidMount() {
		this.reload();
	}

	//onchange selected user reload the table
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedUser !== this.props.selectedUser) {
			this.reload();
		}
	}

	async reload() {
		this.setState({ loading: true });
		let resAllContattiClienti = await getAllContactsByTipologiaRicerca(
			username,
			token /*, 1*/,
			this.props.tipologiaRicerca.id,
			"Tutti",
			"",
			this.props.selectedUser
		);
		let resContattiStatsClienti = await getContattiStatsByTipologiaRicerca(
			username,
			token,
			this.props.tipologiaRicerca.id,
			"Tutti",
			"",
			this.props.selectedUser
		);
		let resRicerche = await getAllRicercheByTipologiaRicerca(username, token, this.props.tipologiaRicerca.id);

		this.setState({
			maxPage: resAllContattiClienti.max_page,
			loading: false,
			stats: resContattiStatsClienti,
			rows: resAllContattiClienti.profili,
			ricerche: resRicerche.ricerche,
			total: resContattiStatsClienti.total,
		});
	}

	render() {
		return (
			<div
				style={{
					width: "92%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				{this.state.modalUpdate.show && (
					<AggiornamentoModalClienti
						onReload={() =>
							this.handleFilterStato(this.state.filterStato, this.state.filter, this.props.selectedUser)
						}
						modal={this.state.modalUpdate}
						onCancel={this.cancel}
					/>
				)}
				<DeleteModal modal={this.state.modal} onConfirmedDelete={this.confirmedDelete} onCancel={this.cancel} />
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={this.state.toastAlert.open}
						autoHideDuration={6000}
						onClose={this.handleCloseAlert}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
					>
						<Alert
							onClose={this.handleCloseAlert}
							severity={this.state.toastAlert.severity}
							sx={{ width: "100%" }}
						>
							{this.state.toastAlert.message}
						</Alert>
					</Snackbar>
				</Stack>

				{/* HEADER */}
				<div className="d-flex align-items-center align-content-center">
					<TextField
						InputProps={{
							disableUnderline: true,
						}}
						label="Cerca"
						variant="standard"
						className="p-0 me-2 mb-2"
						onChange={event => {
							this.setState({ filter: event.target.value });
						}}
						onKeyDown={event => {
							if (event.key === "Enter") {
								this.handleFilterStato(
									this.state.filterStato,
									event.target.value,
									this.props.selectedUser
								);
							}
						}}
					/>
					<div className="ms-3 d-flex">
						<div className="me-2">
							<Typography variant="p">Stato</Typography>
							<Select
								defaultValue="Tutti"
								onChange={async event => {
									this.setState({ filterStato: event.target.value });
									await this.handleFilterStato(
										event.target.value,
										this.state.filter,
										this.props.selectedUser
									);
								}}
							>
								<MenuItem value="Tutti">Tutti</MenuItem>
								{statoSelect.map((stato, i) => (
									<MenuItem key={stato + i} value={stato.value}>
										{stato.value}
									</MenuItem>
								))}
							</Select>
						</div>
					</div>
					{/* <Pagination className='m-0 me-2 ms-auto'>
                        {items}
                    </Pagination> */}
				</div>
				<div className="d-flex">
					<p className="mb-1 me-2">
						<strong>Contatti in lista: </strong> {this.state.total}
					</p>
				</div>

				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "75vh" }} key="table_container">
					<Table striped bordered hover responsive key="table" ref={this.tableBodyRef}>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell key={headCell.id} style={{ width: headCell.width + "px" }}>
										{headCell.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<>
							{!this.state.loading && this.state.rows.length > 0 && (
								<LazyLoading
									length={this.state.rows.length}
									items={this.state.rows}
									onRequestPage={() => {}}
								>
									<ReactList
										itemsRenderer={(items, ref) => (
											<TableBody key="table_body" ref={ref}>
												{items}
											</TableBody>
										)}
										itemRenderer={(index, key) => {
											let row = this.state.rows[index];
											return (
												<TableRow key={key}>
													{/* AZIONE */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<Tooltip title="Elimina profilo" placement="top">
															<Button
																variant="text"
																color="inherit"
																onClick={() => this.openModal(row)}
															>
																<FontAwesomeIcon size={"md"} icon={faTrashCan} />
															</Button>
														</Tooltip>
													</TableCell>

													{/* NOME */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															InputProps={{ disableUnderline: true, readOnly: true }}
															inputProps={{ ...inputPadding }}
															defaultValue={row.nome}
														/>
													</TableCell>

													{/* AGGIORNAMENTO ICONA */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<div style={{ marginTop: 5, cursor: "pointer" }}>
															<Badge
																invisible={row.count === 0 ? true : false}
																badgeContent={row.count}
																color="primary"
																onClick={() => {
																	let x = {
																		show: true,
																		id: row.id,
																	};
																	this.setState({ modalUpdate: x });
																}}
															>
																<ChatOutlinedIcon
																	fontSize="small"
																	onClick={() => {
																		let x = {
																			show: true,
																			id: row.id,
																		};
																		this.setState({ modalUpdate: x });
																	}}
																/>
															</Badge>
														</div>
													</TableCell>

													{/* AZIENDA */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															fullWidth
															InputProps={{
																disableUnderline: true,
															}}
															defaultValue={row.azienda}
															onBlur={async ({ target: { value } }) => {
																if (row.azienda === value) return;
																let res = await updateAziendaContattoClienti(
																	username,
																	token,
																	value,
																	row.id
																);
																this.handleApiResponse(res["result"], "Azienda");
															}}
															onKeyDown={async e => {
																if (e.key === "Enter") {
																	if (row.azienda === e.target.value) return;
																	let res = await updateAziendaContattoClienti(
																		username,
																		token,
																		e.target.value,
																		row.id
																	);
																	this.handleApiResponse(res["result"], "Azienda");
																}
															}}
														/>
													</TableCell>

													{/* TELEFONO */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															InputProps={{
																disableUnderline: true,
															}}
															defaultValue={row.telefono}
															onBlur={async ({ target: { value } }) => {
																if (row.telefono === value) return;
																let res = await updateTelefonoContatto(
																	username,
																	token,
																	value,
																	row.id
																);
																this.handleApiResponse(res["result"], "Telefono");
															}}
															onKeyDown={async e => {
																if (e.key === "Enter") {
																	if (row.telefono === e.target.value) return;
																	let res = await updateTelefonoContatto(
																		username,
																		token,
																		e.target.value,
																		row.id
																	);
																	this.handleApiResponse(res["result"], "Telefono");
																}
															}}
														/>
													</TableCell>

													{/* STATO */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<Select
															defaultValue={row.stato ? row.stato : "Non specificato"}
															onChange={async e => {
																if (row.stato === e.target.value) return;
																let res = await updateStatoContatto(
																	username,
																	token,
																	e.target.value,
																	row.id
																);
																this.handleApiResponse(res["result"], "Stato");
															}}
														>
															{statoSelect.map(option => {
																return (
																	<MenuItem key={option.value} value={option.value}>
																		{option.value}
																	</MenuItem>
																);
															})}
														</Select>
													</TableCell>

													{/* EMAIL */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															fullWidth
															InputProps={{
																disableUnderline: true,
															}}
															defaultValue={row.email}
															onBlur={async ({ target: { value } }) => {
																if (row.email === value) return;
																let res = await updateEmailContatto(
																	username,
																	token,
																	value,
																	row.id
																);
																this.handleApiResponse(res["result"], "Email");
															}}
															onKeyDown={async e => {
																if (e.key === "Enter") {
																	if (row.email === e.target.value) return;
																	let res = await updateEmailContatto(
																		username,
																		token,
																		e.target.value,
																		row.id
																	);
																	this.handleApiResponse(res["result"], "Email");
																}
															}}
														/>
													</TableCell>

													{/* ACCOUNT */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															InputProps={{ disableUnderline: true, readOnly: true }}
															inputProps={{ ...inputPadding }}
															defaultValue={row.account_nome_completo}
														/>
													</TableCell>

													{/* ULTIMO AGGIORNAMENTO */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															InputProps={{ disableUnderline: true, readOnly: true }}
															inputProps={{ ...inputPadding }}
															defaultValue={this.getDaysDiffFromToday(row.updated_at)}
														/>
													</TableCell>

													{/* CREATED AT */}
													<TableCell sx={{ padding: "5px 10px" }}>
														<TextField
															InputProps={{ disableUnderline: true, readOnly: true }}
															inputProps={{ ...inputPadding }}
															defaultValue={row.created_at}
														/>
													</TableCell>
												</TableRow>
											);
										}}
										length={this.state.rows.length}
										type={"simple"}
									/>
								</LazyLoading>
							)}

							{this.state.loading && (
								<TableRow style={{ height: "60vh" }}>
									<TableCell colSpan={10}>
										<div
											className="d-flex justify-content-center align-items-center"
											style={{ height: "100%" }}
										>
											<ReactLoading type="bubbles" color="orange" height={"15%"} width={"15%"} />
										</div>
									</TableCell>
								</TableRow>
							)}
							{!this.state.loading && this.state.rows.length === 0 && (
								<tr>
									<TableCell colSpan={headCells.length} style={{ textAlign: "center" }}>
										Nessun risultato
									</TableCell>
								</tr>
							)}
						</>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
