import React, { useContext, useEffect, useState } from "react";
import HeBLayout from "../components/HeBLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NotifyContext from "../context/NotifyContext";
import * as Icon from "react-feather";
import Cookies from "universal-cookie";
import getNotifiche from "../services/getNotifiche";
import editNotifiche from "../services/editNotifiche";
import LazyLoading from "react-list-lazy-load";
import ReactList from "react-list";

const cookies = new Cookies();
let username = cookies.get("username", { path: "/" });
let token = cookies.get("token", { path: "/" });

const Notifiche = () => {
	const { countNotify, decrementNotify } = useContext(NotifyContext);

	const [notify, setNotify] = useState([]);

	//chiamata per ricevere le notifiche
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getNotifiche(username, token);

				if (response.result === "ok") {
					setNotify(response.notifiche);
				}
			} catch (error) {
				console.error("Errore durante il recupero delle notifiche:", error);
			}
		};

		if (token && username) {
			fetchData();
		}
	}, []);

	//chiamata per modificare is_read
	const markAsRead = async id => {
		try {
			setNotify(prev => prev.map(notify => (notify.id === id ? { ...notify, is_read: 1 } : notify)));

			await editNotifiche(username, token, id);

			decrementNotify();
		} catch (error) {
			console.error("Errore durante la modifica della notifica:", error);
		}
	};

	//switch per le icone
	const renderIcon = type => {
		switch (type) {
			case "prenotazione":
				return <Icon.Phone />;
			case "lista":
				return <Icon.Clipboard />;
			case "contattabili":
				return <Icon.List />;
			case "chat":
				return <Icon.MessageSquare />;
			default:
				return null;
		}
	};

	return (
		<HeBLayout>
			<div
				style={{
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					paddingInline: "60px",
				}}
			>
				<h4 className="fw-bold">
					Notifiche{" "}
					{countNotify > 0 && (
						<span
							style={{
								width: "14px",
								height: "14px",
								borderRadius: "50%",
								backgroundColor: "red",
								color: "white",
								fontSize: "12px",
								lineHeight: "14px",
								textAlign: "center",
								position: "absolute",
								bot: "20px",
							}}
						>
							{countNotify}
						</span>
					)}
				</h4>

				<div
					style={{
						maxHeight: "90vh",
						overflowY: "auto",
					}}
				>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell align="left"></TableCell>
									<TableCell align="left">Ora</TableCell>
									<TableCell align="left">Notifica</TableCell>
								</TableRow>
							</TableHead>

							{notify.length > 0 && (
								<LazyLoading length={notify.length} items={notify} onRequestPage={() => {}}>
									<ReactList
										itemsRenderer={(items, ref) => (
											<TableBody key="table_body" ref={ref}>
												{items}
											</TableBody>
										)}
										itemRenderer={(index, key) => {
											let el = notify[index];
											return (
												<TableRow
													key={el.id}
													sx={{
														backgroundColor: el.is_read == 0 ? "#fdecceb0" : "none",
														cursor: el.is_read == 0 ? "pointer" : "default",
													}}
													onClick={
														el.is_read == 0
															? () => {
																	markAsRead(el.id);
															  }
															: null
													}
												>
													<TableCell align="left">{renderIcon(el.type)}</TableCell>
													<TableCell align="left">
														Ore: {el.timestamp.split(" ")[1]} <br /> Giorno:&nbsp;
														{el.timestamp.split(" ")[0]}
													</TableCell>
													<TableCell align="left">{el.message}</TableCell>
												</TableRow>
											);
										}}
										length={notify.length}
										type="simple"
									/>
								</LazyLoading>
							)}
						</Table>
					</TableContainer>
				</div>
			</div>
		</HeBLayout>
	);
};

export default Notifiche;
