import React from "react";
import "./style.css";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import imgUser from "../images/user.png";
import Cookies from "universal-cookie";
import setChatReadClienti from "../services/setChatReadClienti";
import ReactList from "react-list";
import LazyLoading from "react-list-lazy-load";
import { MenuItem, Select } from "@mui/material";
import getChatList from "../services/getChatList";
import getChatListSortRead from "../services/getChatListSortRead";
import getUtenti from "../services/getUtenti";

const cookie = new Cookies();
const username = cookie.get("username");
const token = cookie.get("token");
const type = cookie.get("tipo");

export default class ChatList extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.state = {
			letto: true,
			message: undefined,
			file: undefined,
			alertName: false,
			alertFile: false,
			chatsArray: [],
			activeIndex: undefined,
			filtered: [],
			pending: false,
			chatLoading: false,
			filterStato: "Tutti",
			filterFollowUp: "Tutti",
		};
	}

	intentFunct(idx) {
		this.setState({ activeIndex: idx });
	}

	componentDidMount() {
		this.reload();
		const interval = setInterval(() => {
			this.reload();
		}, 30000);
		return () => clearInterval(interval);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.filterStato !== this.state.filterStato ||
			prevState.filterFollowUp !== this.state.filterFollowUp ||
			prevState.chatsArray !== this.state.chatsArray
		) {
			this.setState({ pending: true });
			let new_filtered = this.state.chatsArray.filter(element => {
				if (this.state.filterStato === "Tutti" && this.state.filterFollowUp === "Tutti") {
					return true;
				}
				if (this.state.filterStato === "Tutti" && this.state.filterFollowUp !== "Tutti") {
					return element.follow_up === this.state.filterFollowUp;
				}
				if (this.state.filterStato !== "Tutti" && this.state.filterFollowUp === "Tutti") {
					return element.stato === this.state.filterStato;
				}
				if (this.state.filterStato !== "Tutti" && this.state.filterFollowUp !== "Tutti") {
					return element.stato === this.state.filterStato && element.follow_up === this.state.filterFollowUp;
				}
				return false;
			});
			this.setState({ filtered: new_filtered, pending: false });

			if (prevProps.tipologiaRicerca.id !== this.props.tipologiaRicerca.id) {
				this.reload();
			}
		}
	}
	async reload() {
		this.setState({ chatLoading: true });
		let c;
		if (!this.state.pending) {
			c = await getChatList(username, token, this.props.tipologiaRicerca.id, this.props.selectedUser);
		} else {
			c = await getChatListSortRead(username, token, this.props.tipologiaRicerca.id, this.props.selectedUser);
		}
		this.setState({ chatsArray: c, filtered: c, letto: true, chatLoading: false });
	}

	//on change props selectedUser from parent
	componentDidUpdate(prevProps) {
		if (prevProps.selectedUser !== this.props.selectedUser) {
			this.setState({ chatLoading: true });
			getChatList(username, token, this.props.tipologiaRicerca.id, this.props.selectedUser).then(c => {
				this.setState({ chatsArray: c, filtered: c, letto: true, chatLoading: false });
			});
		}
	}

	singleChat(index, key) {
		let element = this.state.filtered[index];
		if (element.cancellato !== 0) {
			return <div />;
		}
		return (
			<li
				key={Math.random()}
				className={this.state.activeIndex === index ? "list-inline-item chat_active" : "list-inline-item"}
				style={{ borderRadius: 6, borderBottom: this.state.activeIndex === index ? "" : "1px solid gray" }}
			>
				<button
					style={{ backgroundColor: "transparent", border: "none", outline: "none", textAlign: "left" }}
					onClick={() => {
						this.props.onChatSelect(element);
						this.intentFunct(index);
						this.setState({ letto: false });
						setChatReadClienti(username, token, element.destinatario);
						this.reload();
					}}
					className="text-light text-decoration-none"
				>
					<div
						style={{
							paddingTop: 15,
							paddingBottom: 15,
							paddingLeft: 20,
							paddingRight: 20,
							position: "relative",
							cursor: "pointer",
						}}
					>
						<div className="d-flex w-100">
							<div
								className="bg-light rounded-circle p-1 me-3 align-self-center overflow-hidden"
								style={{ borderRadius: 50, width: 41 }}
							>
								<img
									src={imgUser}
									alt="img user"
									style={{
										width: "2rem",
										height: "2.3rem",
										textAlign: "center",
										marginBottom: -6,
										marginLeft: 1,
									}}
								/>
							</div>
							<div style={{ width: element.letto_admin === "0" && this.state.letto ? 242 : 285 }}>
								<h5 style={{ fontSize: 19 }} className="mb-1">
									{element.nome} {element.cognome}
								</h5>
								{element.job_title && (
									<h5 style={{ fontSize: 14 }} className="mb-1">
										{element.job_title.substring(0, 60) +
											(element.job_title.length > 60 ? "..." : "")}
									</h5>
								)}
								<p
									style={{
										color: "#8F99B2",
										fontSize: 14,
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
									className="mb-0"
								>
									{element.messaggio}
								</p>
							</div>
							{element.letto_admin === "0" && this.state.letto && (
								<div
									style={{
										width: 43,
										color: "#8F99B2",
										fontSize: 15,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<FontAwesomeIcon icon={faCircle} className="notify" />
								</div>
							)}
						</div>
					</div>
				</button>
			</li>
		);
	}

	render() {
		return (
			<>
				<div
					className="h-100 listchart"
					style={{ width: "20%", minWidth: 400, overflow: "hidden", borderRight: "1px solid gray" }}
				>
					<div className="px-4 pt-4 pb-2 text-light d-flex">
						<div
							style={{
								paddingTop: 8,
								paddingBottom: 8,
								paddingLeft: 16,
								paddingRight: 16,
								borderRadius: 6,
								backgroundColor: "#36404A",
								border: 0,
								color: "white",
								width: "100%",
							}}
						>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
							<input
								type="text"
								style={{
									paddingTop: 8,
									paddingBottom: 8,
									paddingLeft: 16,
									paddingRight: 16,
									borderRadius: 6,
									backgroundColor: "#36404A",
									border: 0,
									color: "white",
									outline: "none",
								}}
								id=""
								placeholder="Ricerca utenti"
								onChange={event => {
									let newArray = [];
									this.setState({ search: event.target.value });
									newArray = this.state.chatsArray.filter(user => {
										return (
											(user.nome + " " + user.cognome)
												.toLowerCase()
												.includes(event.target.value.toLowerCase()) ||
											(user.cognome + " " + user.nome)
												.toLowerCase()
												.includes(event.target.value.toLowerCase())
										);
									});
									this.setState({ filtered: event.target.value ? newArray : this.state.chatsArray });
								}}
							/>
						</div>
					</div>
					<div className="form-check ms-4 mb-2 text-light d-flex align-center justify-content-between">
						{/* <div>
                            <input className="form-check-input" type="checkbox" value={this.state.toRead} id="flexCheckDefault"
                                onClick={async (e) => {
                                    this.setState({ pending: !this.state.pending, chatLoading: true })
                                    let c
                                    if (!e.target.checked) {
                                        c = await getChatList(username, token, this.props.tipologiaRicerca.id, this.props.selectedUser);
                                    } else {
                                        c = await getChatListSortRead(username, token, this.props.tipologiaRicerca.id, this.props.selectedUser);
                                    }
                                    this.setState({ chatsArray: c, filtered: c, letto: true, chatLoading: false })
                                }}
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Da leggere: {this.state.chatsArray.filter((chat) => { return chat?.letto_admin === '0' }).length}
                            </label>
                        </div> */}
						<div className="d-flex align-center">
							{this.state.chatLoading && <ReactLoading height={16} type="cylon" />}
						</div>
					</div>

					{/* LISTA UTENTI */}

					<div className="px-2" style={{ height: "calc(100% - 115px)" }}>
						<h5 className="mb-3 px-3 text-light">Recenti</h5>

						{/* <div className="d-flex justify-content-around">
                            <TextField
                                select
                                value={this.state.filterStato}
                                onChange={(e) => {
                                    this.setState({ filterStato: e.target.value })
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: '#36404A',
                                        color: 'white',
                                        outlineColor: 'white',
                                        borderRadius: 6,
                                        padding: 4
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: 'white',
                                        fontSize: 14
                                    }
                                }}
                                variant="filled"
                                style={{ width: '45%', backgroundColor: '#303841', color: 'white', borderRadius: 6, marginBottom: 10 }}
                                label="Stato">
                                <MenuItem value='Tutti'>Tutti</MenuItem>
                                {
                                    statoSelect.map((stato, i) => {
                                        return (
                                            <MenuItem value={stato.value} key={stato + i}>{stato.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                            <TextField
                                select
                                value={this.state.filterFollowUp}
                                onChange={(e) => {
                                    this.setState({ filterFollowUp: e.target.value })
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: '#36404A',
                                        color: 'white',
                                        outlineColor: 'white',
                                        borderRadius: 6,
                                        padding: 4
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: 'white',
                                        fontSize: 14
                                    }
                                }}
                                variant="filled"
                                style={{ width: '45%', backgroundColor: '#303841', color: 'white', borderRadius: 6, marginBottom: 10 }}
                                label="Follow Up">
                                <MenuItem value='Tutti'>Tutti</MenuItem>
                                {
                                    followUpSelect.map((followUp, i) => {
                                        return (
                                            <MenuItem value={followUp.value} key={followUp + i}>{followUp.value}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                        </div> */}

						<div
							className="lista-utenti"
							style={{ height: "calc(100% - 120px)", overflowY: "scroll", overflowX: "hidden" }}
						>
							<ul className="list-inline p-2">
								{this.state.chatsArray.length !== 0 ? (
									<LazyLoading
										length={this.state.filtered.length}
										items={this.state.filtered}
										onRequestPage={() => {}}
									>
										<ReactList
											itemRenderer={this.singleChat.bind(this)}
											length={this.state.filtered.length}
											type={"simple"}
										/>
									</LazyLoading>
								) : (
									<li className="d-flex justify-content-center" style={{ marginTop: 250 }}>
										<h4 className="text-light">Nessuna conversazione</h4>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</>
		);
	}
}
