import React from "react";
import Cookies from "universal-cookie";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import Button from "@mui/material/Button";
import MailIcon from "@mui/icons-material/Mail";

//API
import getAllCalls from "../../services/getAllCalls";
import deleteCalls from "../../services/deleteCall";
import searchAllCallsByName from "../../services/searchAllCallsByName";
import getChiamateResidue from "../../services/getChiamateResidue";
import getPacchettiChiamate from "../../services/getPacchettiChiamate";
import sendMail from "../../services/sendMail";

import { Badge, Box, TextField } from "@mui/material";

import ReactList from "react-list";
import LazyLoading from "react-list-lazy-load";

import MessaggiModalCall from "./MessaggiModalCall";
import PacchettiModal from "./PacchettiModal";

import moment from "moment";
import checkPayment from "../../services/checkPayment";
import StatusModal from "./StatusModal";
import Constants from "../../utils/consts";
import getUtente from "../../services/getUtente";

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });

const headCells = [
	{
		label: "Nome",
		id: "nome",
	},
	{
		label: "Email",
		id: "email",
	},
	{
		label: "Numero di telefono",
		id: "numero di telefono",
	},
	{
		label: "Data e ora",
		id: "data",
	},
	{
		label: "Messaggi",
		id: "messaggi",
	},
];

const inputPadding = { style: { padding: 4, minWidth: "250px" } };

export default class ListeTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			page: 1,
			to_contacted: 0,
			filter: "",
			reorder: false,
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			modal_pacchetti: {
				show: false,
			},
			user: 0,
			balance: 0,
			pacchetti: [],
			statusModal: null,
			loading: true,
		};
	}

	componentDidMount() {
		this.reload();
		const url = window.location.href;
		const queryParams = new URLSearchParams(url.split("?")[1]);

		// Verifica se la query string contiene il parametro 'stato'
		if (queryParams.has("status")) {
			const valueStato = queryParams.get("status");
			// Verifica il valore di 'stato'
			if (valueStato === "success") {
				this.check();
			} else if (valueStato === "error") {
				this.setState({ statusModal: "error" });
			} else {
				this.setState({ statusModal: null });
			}
		} else {
			this.setState({ statusModal: null });
		}
	}

	async check() {
		let count = 0;
		let success = false;
		const url = window.location.href;

		// Utilizza URLSearchParams per gestire la query string
		const queryParams = new URLSearchParams(url.split("?")[1]);

		// Estrai il valore di 'referer' dalla query string
		const referer = queryParams.has("referer") ? queryParams.get("referer") : null;

		// Verifica se 'referer' è presente
		if (!referer) {
			window.location.href = "/dashboard";
			return;
		}

		while (count < 5) {
			try {
				let res = await checkPayment(username, token, referer);
				if (res.result === "ok") {
					success = true;
					this.setState({ statusModal: "success" });
					const body = Constants.getAcquisto(res.chiamate, res.importo, new Date());
					const utente = await getUtente(username, token);
					await sendMail(utente.email, utente.ragione_sociale, "Conferma acquisto pacchetto chiamate", body);
					break;
				} else if (res.message === "Payment already used") {
					success = true;
					this.setState({ statusModal: "success" });
					break;
				}

				count++;
			} catch (error) {
				count++;
			}
		}

		if (!success) {
			this.setState({ statusModal: "error" });
		}
	}

	async reload() {
		this.setState({ loading: true });
		await getPacchettiChiamate().then(res => {
			this.setState({ pacchetti: res });
		});
		await getChiamateResidue(username, token).then(res => {
			this.setState({ balance: res.balance });
		});

		await searchAllCallsByName(username, token, 1, "", false, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento degli account",
						severity: "error",
					},
				});
				return;
			}
			this.setState({
				rows: res.profili,
				/*maxPage: res.max_page, total: res.total*/ to_contacted: res.to_contacted,
				loading: false,
			});
		});
	}

	addRow = () => {
		const { rows } = this.state;
		const newRow = {
			id: rows.map(row => row.id).reduce((max, cur) => Math.max(max, cur), 0) + 1,
			nome: "",
			email: "",
			telefono: "",
			data: "",
			new: true,
		};
		this.setState({ rows: [newRow, ...rows] });
	};

	cancel = () => {
		let modal = this.state.modal;
		modal.show = false;
		this.setState({ modal });
	};

	cancel_pacchetti = () => {
		let modal_pacchetti = this.state.modal_pacchetti;
		modal_pacchetti.show = false;
		this.setState({ modal_pacchetti });
	};

	closeStatusModal = () => {
		this.setState({ statusModal: null });
	};

	handleFilter = async (filter, reorder) => {
		this.setState({ loading: true });
		let res = await searchAllCallsByName(username, token, 1, filter, reorder, this.props.selectedUser);
		this.setState({
			rows: res.profili,
			maxPage: res.max_page,
			filter,
			page: 1,
			total: res.total,
			to_contacted: res.to_contacted ? res.to_contacted : 0,
			loading: false,
		});
	};

	handleDelete = async id => {
		let res = await deleteCalls(username, token, id);
		if (res.result === "ok") {
			searchAllCallsByName(
				username,
				token,
				this.state.page,
				this.state.filter,
				this.state.reorder,
				this.props.selectedUser
			).then(res => {
				this.setState({
					rows: res.profili,
					maxPage: res.max_page,
					total: res.total,
					to_contacted: res.to_contacted ? res.to_contacted : 0,
				});
			});
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.selectedUser !== prevProps.selectedUser) {
			this.reload();
		}
	}

	tableBodyRef = React.createRef();

	render() {
		return (
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				{/* MODAL */}
				{this.state.statusModal && (
					<StatusModal
						show={true}
						onHide={() => this.setState({ statusModal: null })}
						title={
							this.state.statusModal === "success"
								? "Grazie per l'acquisto"
								: "Errore durante il pagamento"
						}
						message={
							this.state.statusModal === "success"
								? "Pagamento completato!"
								: "Si è verificato un errore."
						}
						buttonText="Chiudi"
					/>
				)}
				{this.state.modal.show && <MessaggiModalCall modal={this.state.modal} onCancel={this.cancel} />}
				{this.state.modal_pacchetti.show && (
					<PacchettiModal
						modal={this.state.modal_pacchetti}
						onCancel={this.cancel_pacchetti}
						pacchetti={this.state.pacchetti}
					/>
				)}
				{/* TABLE */}
				<Box sx={{ width: "100%" }}>
					<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<TextField
								InputProps={{
									disableUnderline: true,
								}}
								label="Cerca"
								variant="standard"
								className="p-0 me-2 mb-2 mt-3"
								onChange={event => {
									this.setState({ filter: event.target.value });
								}}
								onKeyDown={event => {
									if (event.key === "Enter") {
										this.handleFilter(event.target.value, this.state.reorder);
									}
								}}
								onBlur={event => {
									this.handleFilter(event.target.value, this.state.reorder);
								}}
							/>
						</Box>
						<Button
							className="myButton"
							variant="contained"
							onClick={() => {
								let modal_pacchetti = this.state.modal_pacchetti;
								modal_pacchetti.show = true;
								this.setState({ modal_pacchetti });
							}}
						>
							Chiamate residue: {this.state.balance}
						</Button>
					</Box>
					<TableContainer component={Paper} sx={{ overflow: "auto", height: "80vh" }}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table" ref={this.tableBodyRef}>
							<TableHead>
								<TableRow>
									{headCells.map(headCell => (
										<TableCell key={headCell.id} align={headCell.alignRight ? "right" : "left"}>
											{headCell.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<>
								{!this.state.loading && this.state.rows.length > 0 && (
									<LazyLoading
										length={this.state.rows.length}
										items={this.state.rows}
										onRequestPage={() => {}}
									>
										<ReactList
											itemsRenderer={(items, ref) => <TableBody ref={ref}>{items}</TableBody>}
											itemRenderer={(index, key) => {
												let row = this.state.rows[index];
												return (
													<TableRow
														key={row.id}
														sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
													>
														<TableCell
															sx={{ padding: "5px 10px" }}
															component="th"
															scope="row"
														>
															<TextField
																InputProps={{
																	disableUnderline: true,
																}}
																className="custom-input"
																variant="standard"
																inputProps={inputPadding}
																defaultValue={row.nome}
															/>
														</TableCell>
														<TableCell sx={{ padding: "5px 10px" }} align="left">
															<TextField
																InputProps={{
																	disableUnderline: true,
																}}
																variant="standard"
																inputProps={inputPadding}
																defaultValue={row.email}
															/>
														</TableCell>
														<TableCell sx={{ padding: "5px 10px" }} align="left">
															<TextField
																InputProps={{
																	disableUnderline: true,
																}}
																variant="standard"
																inputProps={inputPadding}
																defaultValue={row.telefono}
															/>
														</TableCell>
														<TableCell sx={{ padding: "5px 10px" }} align="left">
															<TextField
																InputProps={{
																	disableUnderline: true,
																}}
																variant="standard"
																inputProps={inputPadding}
																defaultValue={moment
																	.utc(row.data)
																	.format("DD/MM/YYYY HH:mm")}
															/>
														</TableCell>
														<TableCell sx={{ padding: "5px 10px" }} align="left">
															<Badge
																className="cursor_pointer"
																onClick={() => {
																	if (!row.message_count || row.message_count < 1)
																		return;
																	let modal = this.state.modal;
																	modal.show = true;
																	modal.fullName = row.nome;
																	modal.id = row.id;
																	this.setState({ modal });
																}}
																badgeContent={row.message_count}
																color="primary"
															>
																<MailIcon
																	onClick={() => {
																		if (!row.message_count || row.message_count < 1)
																			return;
																		let modal = this.state.modal;
																		modal.show = true;
																		modal.fullName = row.nome;
																		modal.id = row.id;
																		this.setState({ modal });
																	}}
																	color="action"
																/>
															</Badge>
														</TableCell>
														{/*<TableCell align="left">
                                                <IconButton aria-label="Elimina" onClick={() => this.handleDelete(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </TableCell> */}
													</TableRow>
												);
											}}
											length={this.state.rows.length}
											type={"simple"}
										/>
									</LazyLoading>
								)}
								{this.state.loading && (
									<TableRow style={{ height: "60vh" }}>
										<TableCell colSpan={5}>
											<div
												className="d-flex justify-content-center align-items-center"
												style={{ height: "100%" }}
											>
												<ReactLoading
													type="bubbles"
													color="orange"
													height={"15%"}
													width={"15%"}
												/>
											</div>
										</TableCell>
									</TableRow>
								)}
								{!this.state.loading && this.state.rows.length === 0 && (
									<tr>
										<TableCell colSpan={headCells.length} style={{ textAlign: "center" }}>
											Nessun risultato
										</TableCell>
									</tr>
								)}
							</>
						</Table>
					</TableContainer>
				</Box>
			</div>
		);
	}
}
