/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Cookies from "universal-cookie";
import "./style.css";
import HeBLayout from "../components/HeBLayout";
import PreferitiChatTest from "../components/PreferitiChatTest";
import UserChatTest from "../components/UserChatTest";
import getMessagesForTest from "../services/getMessagesForTest";
import addMessageForTest from "../services/addMessageForTest";
import addPredefinitoForTest from "../services/addPredefinitoForTest";
import deleteMessageForTest from "../services/deleteMessageForTest";
import getCampagna from "../services/getCampagna";
import getCampagnaId from "../services/getCampagnaId";
import { MenuItem, Select } from "@material-ui/core";
import NotifyContext from "../context/NotifyContext";

const cookies = new Cookies();
const username = cookies.get("username", { path: "/" });
const token = cookies.get("token", { path: "/" });
const id = cookies.get("id", { path: "/" });

const ChatTest = () => {
	const { selectedUser } = useContext(NotifyContext);
	const [predefiniti, setPredefiniti] = useState([]);
	const [messages, setMessages] = useState([]);
	const [testMessages, setTestMessages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [campagne, setCampagne] = useState([]);
	const [campagnaSelected, setCampagnaSelected] = useState(null);
	const [firstLoad, setFirstLoad] = useState(true);
	const [prompt, setPrompt] = useState("");

	const getMessages = async changeSelect => {
		const res = await getMessagesForTest(username, token, selectedUser);

		if (res.result === "OK") {
			setMessages(res.messages);
			setTestMessages(Number(res.test_messages));
			setPrompt(res.prompt);
			if (!changeSelect) {
				setCampagnaSelected(res.campagna_id);
			}
		} else {
			setMessages([]);
			setTestMessages(0);
			setCampagnaSelected(null);
			setPrompt("");
		}
	};

	const getCampagne = async (username, token, id) => {
		const response = await getCampagna(username, token, id);
		if (response.result === "KO") {
			return alert("Errore durante il caricamento delle campagne");
		}
		setCampagne(response.data);
	};

	const getPredefiniti = async () => {
		if (!campagnaSelected) return;
		const campagnapredefiniti = await getCampagnaId(username, token, campagnaSelected, selectedUser);
		if (campagnapredefiniti !== null && campagnapredefiniti.data.predefiniti.length > 0) {
			setPredefiniti(JSON.parse(campagnapredefiniti.data.predefiniti));
		}
	};

	const addMessage = async message => {
		if (testMessages <= 0) {
			alert(
				"Hai esaurito i messaggi disponibili. Acquista un nuovo pacchetto di chiamate per ricaricare i messaggi di test"
			);
			return;
		}

		if (message.length === 0 || message.trim().length === 0) {
			return;
		}

		setLoading(true);
		setMessages(messages => [
			...messages,
			{
				messaggio: message,
				sender: "user",
				date: new Date(),
			},
		]);
		let success = false;

		for (let i = 0; i < 5; i++) {
			if (success) {
				break;
			}

			const res = await addMessageForTest(username, token, message, campagnaSelected, selectedUser);
			if (res.result === "OK" && res.http_code !== 400) {
				success = true;
			}
		}

		setLoading(false);

		if (!success) {
			alert("Errore durante l'invio del messaggio");
		}

		await getMessages();
	};

	const deleteMessage = async () => {
		if (!window.confirm("Sei sicuro di voler resettare la conversazione?")) return;
		const res = await deleteMessageForTest(username, token, campagnaSelected, selectedUser);
		if (res.result === "OK") {
			await getMessages();
		}
	};

	const addPredefinito = async id => {
		const res = await addPredefinitoForTest(username, token, id, selectedUser);
		if (res.result === "OK") {
			await getMessages();
		}
	};

	useEffect(() => {
		const getPredefiniti = async () => {
			if (!campagnaSelected) {
				return;
			}
			const campagnapredefiniti = await getCampagnaId(username, token, campagnaSelected, selectedUser);
			if (campagnapredefiniti !== null && campagnapredefiniti.data.predefiniti.length > 0) {
				setPredefiniti(JSON.parse(campagnapredefiniti.data.predefiniti));
			}
			if (firstLoad) {
				setFirstLoad(false);
				return;
			}
			deleteMessageForTest(username, token, campagnaSelected, selectedUser).then(() => {
				getMessages(true);
			});
		};
		getPredefiniti();
	}, [campagnaSelected, selectedUser]);

	useEffect(() => {
		getMessages();
		getCampagne(username, token, selectedUser);
	}, [selectedUser]);

	return (
		<HeBLayout>
			<div className="chat_container glass_background position-relative">
				<div
					className="h-100 listchart"
					style={{
						width: "20%",
						minWidth: 400,
						overflow: "scroll",
						borderRight: "1px solid gray",
					}}
				>
					<div className="p-5 text-white fw-bold">
						<h4>Chat test</h4>
						<p style={{ fontSize: 16 }}>
							Testa i messaggi predefiniti, rispondi come farebbe un utente e verifica le risposte del tuo
							chatbot.
						</p>
						{testMessages > 0 && (
							<p
								style={{
									fontSize: 16,
									textDecoration: "underline",
								}}
							>
								Hai a disposizione {testMessages} messaggi di test
							</p>
						)}
						{!loading && testMessages <= 0 && (
							<p className="text-danger" style={{ fontSize: 16 }}>
								Hai esaurito i messaggi di test. Acquista un nuovo pacchetto di chiamate per ricaricare
								i messaggi di test
							</p>
						)}
						<hr />
						<h4>Seleziona la campagna</h4>
						<Select
							style={{
								width: "100%",
								color: "white",
								borderRadius: 5,
							}}
							InputProps={{
								disableUnderline: true,
							}}
							readOnly={false}
							value={campagnaSelected}
							onChange={e => setCampagnaSelected(e.target.value)}
						>
							{campagne.map((campagna, index) => {
								return (
									<MenuItem key={index} value={campagna.id_campagna}>
										{campagna.nome}
									</MenuItem>
								);
							})}
						</Select>
						<hr />
						<h4>Prompt</h4>
						<p style={{ fontSize: 16 }}>{prompt}</p>
					</div>
				</div>
				<UserChatTest messages={messages} onSend={addMessage} loading={loading} deleteMessage={deleteMessage} />
				{predefiniti !== null && (
					<PreferitiChatTest
						predefiniti={predefiniti}
						getPredefinitiResp={getPredefiniti}
						addPredefinito={addPredefinito}
					/>
				)}
			</div>
		</HeBLayout>
	);
};

export default ChatTest;
