import React from "react";
import Cookies from "universal-cookie";

//MUI
import { Box, Chip, CircularProgress, DialogActions, FormControl, Grid, MenuItem, Tooltip } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

//API
import getUtenti from "../../services/getUtenti";
import getListeCreazione from "../../services/getListeCreazione";
import filterListCreate from "../../services/filterListCreate";
import addListaCreate from "../../services/addListaCreate";
import editListCreate from "../../services/editListCreate";
import deleteList from "../../services/deleteList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { Alert, Select, Stack } from "@mui/material";
import { Snackbar, TextField } from "@material-ui/core";

import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import MapsInput from "../MapsInput";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const cookie = new Cookies();
const username = cookie.get("username", { path: "/" });
const token = cookie.get("token", { path: "/" });
const type = cookie.get("tipo");

const headCells = [
	{
		id: "name_cliente",
		numeric: false,
		disablePadding: false,
		label: "Nome lista",
	},
	{
		id: "stato_creazione",
		numeric: false,
		disablePadding: false,
		label: "Stato",
	},
	{
		id: "localita",
		numeric: false,
		disablePadding: false,
		label: "Località",
	},
	{
		id: "localita_da_escludere",
		numeric: false,
		disablePadding: false,
		label: "Località da escludere",
	},
	{
		id: "settore",
		numeric: false,
		disablePadding: false,
		label: "Settore",
	},
	{
		id: "funzioni",
		numeric: false,
		disablePadding: false,
		label: "Funzioni",
	},
	{
		id: "numero_dipendenti",
		numeric: false,
		disablePadding: false,
		label: "Numero dipendenti",
	},
	{
		id: "anni_di_esperienza",
		numeric: false,
		disablePadding: false,
		label: "Anni di esperienza",
	},
	{
		id: "lingua",
		numeric: false,
		disablePadding: false,
		label: "Lingua",
	},
	{
		id: "parole_chiave",
		numeric: false,
		disablePadding: false,
		label: "Parole chiave",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "Azioni",
	},
];

const numeroDipendentiOptions = [
	"Lavoratore autonomo",
	"1-10",
	"11-50",
	"51-200",
	"201-500",
	"501-1000",
	"1001-5000",
	"5001-10.000",
	"10.000+",
];

const settoriOptions = [
	"Chiropratici",
	"Fabbricazione di apparecchiature per il settore spaziale e della difesa",
	"Optometristi",
	"Fabbricazione di hardware per computer",
	"Sviluppo di software",
	"Prodotti di networking per computer",
	"Fabbricazione di mezzi di trasporto",
	"Tecnologia, informazioni e internet",
	"Fisioterapisti, terapisti occupazionali e logopedisti",
	"Fabbricazione di semiconduttori",
	"Telecomunicazioni",
	"Studio legale",
	"Programmi di edilizia abitativa",
	"Servizi legali",
	"Consulenza e servizi aziendali",
	"Ricerca biotecnologica",
	"Centri di pianificazione familiare",
	"Studi medici",
	"Programmi di trasporto",
	"Ospedali e strutture sanitarie",
	"Amministrazione dei servizi di pubblica utilità",
	"Fabbricazione di prodotti farmaceutici",
	"Centri di assistenza ambulatoriali",
	"Servizi veterinari",
	"Fabbricazione di apparecchiature medicali",
	"Ricerca spaziale e tecnologia",
	"Fabbricazione di parti per autoveicoli",
	"Fabbricazione di prodotti per la cura personale",
	"Vendita al dettaglio di abbigliamento e moda",
	"Fabbricazione di articoli sportivi",
	"Produzione di tabacco",
	"Laboratori medici e diagnostici",
	"Vendita al dettaglio di generi alimentari",
	"Fabbricazione di prodotti alimentari e bevande",
	"Estrazione petrolifera",
	"Fabbricazione di computer e apparecchiature elettroniche",
	"Estrazione di gas naturale",
	"Industria manifatturiera",
	"Fabbricazione di mobili e arredi per la casa",
	"Servizi di assistenza sanitaria domiciliare",
	"Vendita al dettaglio",
	"Prodotti software integrati",
	"Fornitori di attività di intrattenimento",
	"Prodotti software per dispositivi mobili",
	"Strutture per gioco d'azzardo e casinò",
	"Servizi di ambulanza",
	"Prodotti software per computer desktop",
	"Sviluppo di software personalizzati per sistemi IT",
	"Organizzazione di viaggi",
	"Esercizio e manutenzione dei sistemi IT",
	"Settore alberghiero",
	"Installazione e dismissione di sistemi IT",
	"Ristoranti",
	"Formazione e supporto per sistemi IT",
	"Ospedali",
	"Sport professionistici",
	"Servizi dati per sistemi IT",
	"Servizi per la ristorazione",
	"Test e valutazione dei sistemi IT",
	"Film, video e audio",
	"Produzione e distribuzione di contenuti radiotelevisivi",
	"Musei, siti storici e giardini zoologici",
	"Artisti e scrittori",
	"Arti dello spettacolo",
	"Strutture ricreative",
	"Settore bancario",
	"Assicurazioni",
	"Case di riposo e strutture di assistenza residenziale",
	"Servizi finanziari",
	"Immobiliare",
	"Banche d'investimento",
	"Gestione investimenti",
	"Contabilità",
	"Edilizia",
	"Vendita all’ingrosso di materiali da costruzione",
	"Architettura e pianificazione",
	"Ingegneria civile",
	"Notizie su Internet",
	"Fabbricazione di componenti per il settore aeronautico e aerospaziale",
	"Blog",
	"Fabbricazione di autoveicoli",
	"Interior design",
	"Fabbricazione di prodotti chimici",
	"Piattaforme di social networking",
	"Produzione di macchinari",
	"Fabbricazione di mobili per la casa e per le istituzioni",
	"Piattaforme di Business intelligence",
	"Attività minerarie",
	"Contenuti aziendali",
	"Petrolio e gas",
	"Prodotti software per la sicurezza dei dati",
	"Cantieri navali",
	"Servizi pubblici",
	"App di gioco per piattaforme mobili",
	"Produzione tessile",
	"Pubblicazione su Internet",
	"Fabbricazione di carta e prodotti cartacei",
	"Mezzi di comunicazione e telecomunicazioni",
	"Fabbricazione di attrezzature ferroviarie",
	"Servizi di blockchain",
	"Agricoltura",
	"Servizi per anziani e disabili",
	"Allevamento",
	"Produzione lattiero-casearia",
	"Fabbricazione di mobili e arredi per l'ufficio",
	"Acquacoltura",
	"Servizi alla collettività",
	"Istruzione primaria e secondaria",
	"Istruzione superiore",
	"Programmi di amministrazione scolastica",
	"Servizi di ricerca",
	"Fabbricazione di materassi e tende",
	"Forze armate",
	"Uffici legislativi",
	"Amministrazione della giustizia",
	"Affari internazionali",
	"Servizi di emergenza e di soccorso",
	"Pubblica amministrazione",
	"Uffici esecutivi",
	"Forze dell’ordine",
	"Servizi di riabilitazione professionale",
	"Pubblica sicurezza",
	"Uffici di politica pubblica",
	"Servizi pubblicitari",
	"Servizi di assistenza per i bambini",
	"Editoria: quotidiani",
	"Arti dello spettacolo e sport professionistico",
	"Editoria: libri e pubblicazioni periodiche",
	"Servizi di stampa",
	"Servizi informativi",
	"Biblioteche",
	"Compagnie teatrali",
	"Servizi ambientali",
	"Trasporto di merci e pacchi",
	"Compagnie di danza",
	"Servizi per singoli individui e famiglie",
	"Istituzioni religiose",
	"Organizzazioni civiche e sociali",
	"Servizi ai consumatori",
	"Circhi e spettacoli di magia",
	"Trasporto su strada",
	"Immagazzinaggio e stoccaggio",
	"Squadre sportive e club",
	"Linee aeree e aviazione",
	"Trasporto marittimo",
	"Circuiti",
	"Servizi IT e consulenza IT",
	"Ricerche di mercato",
	"Pubbliche Relazioni e servizi di comunicazione",
	"Servizi di progettazione",
	"Organizzazioni senza scopo di lucro",
	"Raccolta fondi",
	"Servizi di gestione strategica",
	"Redazione ed editing",
	"Selezione e ricerca di personale",
	"Vendita all’ingrosso di autoveicoli e relative parti",
	"Coaching e formazione professionale",
	"Mandanti di venture capital e private equity",
	"Organizzazioni politiche",
	"Traduzione e localizzazione",
	"Videogiochi",
	"Servizi per eventi",
	"Vendita al dettaglio di materiali artistici",
	"Musei",
	"Fabbricazione di apparecchi elettrici, materiali elettrici e componenti elettronici",
	"Contenuti audio e video online",
	"Vendita all’ingrosso di mobili e arredi per la casa",
	"Siti storici",
	"Ricerca nanotecnologica",
	"Commercianti d’arte al dettaglio",
	"Musicisti",
	"Giardini zoologici e botanici",
	"Trasporti, logistica, supply chain e stoccaggio",
	"Fabbricazione di materie plastiche",
	"Sicurezza informatica e delle reti",
	"Servizi wireless",
	"Parchi di divertimento e sale giochi",
	"Risoluzione alternativa delle controversie",
	"Sicurezza e investigazioni",
	"Servizi infrastrutturali",
	"Consulenza outsourcing e offshoring",
	"Servizi benessere e fitness",
	"Medicina alternativa",
	"Produzione di media",
	"Animazione e post-produzione",
	"Locazione di beni immobili non residenziali",
	"Mercati dei capitali",
	"Vendita all’ingrosso di apparecchiature e materiali fotografici",
	"Think tank",
	"Servizi di raccolta fondi filantropici",
	"Campi da golf e country club",
	"Fornitori di e-learning",
	"Vendita all'ingrosso",
	"Vendita all’ingrosso di apparecchiature informatiche",
	"Impianti sciistici",
	"Importazione ed esportazione all’ingrosso",
	"Fabbricazione di macchinari industriali",
	"Fotografia",
	"Servizi risorse umane",
	"Vendita al dettaglio di attrezzature per ufficio",
	"Servizi di salute mentale",
	"Graphic design",
	"Commercio internazionale e sviluppo",
	"Produzione di bevande",
	"Servizi di alloggio e ristorazione",
	"Vendita all’ingrosso di metalli e minerali",
	"Vendita al dettaglio di beni di lusso e gioielli",
	"Fabbricazione di vetro, ceramica e calcestruzzo",
	"Fabbricazione di materiali da imballaggio e contenitori",
	"Alberghi e motel",
	"Fabbricazione di macchinari di automazione",
	"Vendita all’ingrosso di apparecchi elettrici, materiali elettrici e componenti elettronici",
	"Servizi di rapporti con le istituzioni governative",
	"Bed and breakfast, ostelli, soggiorni in famiglia",
	"Orticoltura",
	"Vendita all’ingrosso di ferramenta e apparecchi per impianti idraulici e di riscaldamento",
	"Vendita all’ingrosso di macchinari",
	"Catering",
	"Servizi di ristorazione mobile",
	"Produzione di energia da fonti rinnovabili",
	"Bar, pub e nightclub",
	"Produzione attrezzature energie rinnovabili",
	"Servizi ingegneristici",
	"Servizi per energie rinnovabili",
	"Servizi per l’accessibilità digitale",
	"Produzione di hardware accessibile",
	"Architettura e design accessibili",
	"Produzione di robot",
	"Ingegneria robotica",
	"Riparazione e manutenzione",
	"Servizi di rilevamento e mappatura",
	"Riparazione e manutenzione veicoli",
	"Farmacie al dettaglio",
	"Produzione di tecnologie per il clima",
	"Analisi e dati sul clima",
	"Produzione di veicoli ad alimentazione alternativa",
	"Vendita all’ingrosso di materiali riciclabili",
	"Produzione di contatori intelligenti",
	"Produzione di celle a combustibile",
	"Vendita all’ingrosso di beni di lusso e gioielli",
	"Progettazione rigenerativa",
	"Vendita all’ingrosso di prodotti cartacei",
	"Manutenzione di apparecchiature elettroniche e di precisione",
	"Vendita all’ingrosso di farmaci e articoli vari",
	"Vendita all’ingrosso di abbigliamento e articoli di merceria",
	"Manutenzione di macchinari per uso commerciale e industriale",
	"Agricoltura, allevamento, silvicoltura",
	"Servizi di tappezzeria e restauro mobili",
	"Vendita all’ingrosso di calzature",
	"Vendita all’ingrosso di prodotti alimentari e bevande",
	"Riparazione di calzature e articoli in pelle",
	"Servizi per la persona e di lavanderia",
	"Servizi per la cura personale",
	"Servizi di lavanderia e pulitura a secco",
	"Vendita all’ingrosso di prodotti agricoli non lavorati",
	"Vendita all’ingrosso di prodotti chimici e affini",
	"Servizi per animali domestici",
	"Vendita all’ingrosso di petrolio e prodotti petroliferi",
	"Vendita all’ingrosso di bevande alcoliche",
	"Allevamento e acquacoltura",
	"Piattaforme di e-commerce",
	"Vendita al dettaglio di motoveicoli",
	"Servizi domestici",
	"Vendita al dettaglio di mobili e arredi per la casa",
	"Vendita al dettaglio di apparecchi lettrici, materiali elettrici e apparecchiature elettroniche",
	"Silvicoltura e utilizzo di aree forestali",
	"Vendita al dettaglio di materiali da costruzione e attrezzature da giardino",
	"Sanità e servizi sociali",
	"Salute pubblica",
	"Programmi di assistenza pubblica",
	"Vendita al dettaglio di prodotti alimentari e bevande",
	"Gestione di programmi in materia di aria, risorse idriche e dei rifiuti",
	"Programmi di conservazione",
	"Sviluppo dell'edilizia abitativa e della comunità",
	"Sviluppo della comunità e pianificazione urbana",
	"Programmi economici",
	"Petrolio, gas e risorse minerarie",
	"Vendita al dettaglio di prodotti per la salute e la cura personale",
	"Estrazione di carbone",
	"Affari militari e internazionali",
	"Miniere di metallo ore",
	"Vendita al dettaglio di benzina",
	"Consulenza attività operative",
	"Estrazione di minerali metalliferi",
	"Trasmissione, controllo e distribuzione di energia elettrica",
	"Vendita al dettaglio di strumenti musicali",
	"Produzione di energia elettrica",
	"Produzione di energia idroelettrica",
	"Vendita al dettaglio di libri e giornali",
	"Produzione di energia elettrica da combustibili fossili",
	"Produzione di energia elettrica nucleare",
	"Produzione di energia elettrica solare",
	"Programmi di qualità ambientale",
	"Produzione di energia elettrica geotermica",
	"Produzione di energia elettrica da biomassa",
	"Distribuzione di gas naturale",
	"Impianti di trattamento e distribuzione idrica, trattamento delle acque reflue, servizi di distribuzione vapore e aria condizionata",
	"Vendita al dettaglio di fiori",
	"Vendita al dettaglio di forniture per ufficio e articoli da regalo",
	"Sistemi di approvvigionamento idrico e di irrigazione",
	"Fornitura di vapore e aria condizionata",
	"Costruzione di edifici",
	"Vendita al dettaglio di materiali riciclabili e oggetti usati",
	"Costruzione di edifici residenziali",
	"Infrastruttura di dati e analisi",
	"Costruzione di edifici non residenziali",
	"Costruzione di opere di pubblica utilità",
	"Fabbricazione di apparecchiature elettriche",
	"Vendita al dettaglio online o per corrispondenza",
	"Suddivisione del terreno",
	"Costruzione di autostrade, strade e ponti",
	"Imprese specializzate",
	"Imprese specializzate in opere strutturali e sistemazioni esterne",
	"Produzione di energia elettrica eolica",
	"Aziende vinicole",
	"Imprese fornitrici di attrezzature per l'edilizia",
	"Trasporto ferroviario",
	"Imprese specializzate in opere di finitura",
	"Trasporto terrestre di passeggeri",
	"Servizi di trasporto urbano",
	"Servizi di autobus in aree rurali e interurbane",
	"Servizi di taxi e limousine",
	"Produzione di mangimi per animali",
	"Servizi di autobus scolastici e per dipendenti",
	"Navette e servizi di trasporto per esigenze speciali",
	"Fabbricazione di zucchero e prodotti dolciari",
	"Trasporto in condotte",
	"Fabbricazione di conserve di frutta e verdura",
	"Trasporto turistico",
	"Lavorazione di prodotti a base di carne",
	"Lavorazione di prodotti ittici",
	"Fabbricazione di prodotti da forno",
	"Servizi postali",
	"Birrifici",
	"Distillerie",
	"Tecnologia, informazione e mezzi di comunicazione",
	"Editoria: pubblicazioni periodiche",
	"Editoria: libri",
	"Film e registrazioni sonore",
	"Produzione di capi di abbigliamento",
	"Registrazione audio",
	"Editoria: spartiti",
	"Diffusione radiotelevisiva",
	"Produzione di accessori di moda",
	"Produzione di articoli in pelle",
	"Programmazione via cavo e satellitare",
	"Vettori di telecomunicazioni",
	"Produzione di calzature",
	"Telecomunicazioni via satellite",
	"Produzione di borse da donna",
	"Intermediazione creditizia",
	"Istituti di risparmio",
	"Mediatori creditizi",
	"Fabbricazione di prodotti petroliferi e carbonieri",
	"Borse valori e borse merci",
	"Fabbricazione di materie prime chimiche",
	"Consulenza di investimento",
	"Compagnie di assicurazioni",
	"Fabbricazione di gomma artificiale e fibre sintetiche",
	"Fabbricazione di prodotti chimici per l’agricoltura",
	"Agenzie di assicurazioni e società di intermediazione",
	"Liquidazione sinistri, servizi attuariali",
	"Fondi e trust",
	"Fondi assicurativi e previdenziali",
	"Fondi pensione",
	"Fabbricazione di vernici, rivestimenti e adesivi",
	"Trust e patrimoni",
	"Fabbricazione di saponi e detergenti",
	"Servizi di locazione di beni immobili e noleggio di apparecchiature",
	"Locazione di beni immobili residenziali",
	"Fabbricazione di prodotti in gomma e materie plastiche",
	"Broker e agenti immobiliari",
	"Servizi di noleggio di apparecchiature",
	"Noleggio di beni di consumo",
	"Fabbricazione di prodotti in gomma",
	"Fabbricazione di prodotti in argilla e refrattari",
	"Noleggio di apparecchiature industriali e commerciali",
	"Fabbricazione di prodotti in vetro",
	"Fabbricazione di prodotti in legno",
	"Servizi professionali",
	"Fabbricazione di prodotti a base di calce e gesso",
	"Fabbricazione di prodotti abrasivi e di minerali non metallici",
	"Fabbricazione di metalli primari",
	"Servizi di progettazione di sistemi IT",
	"Servizi di marketing",
	"Manufatti in metallo",
	"Fabbricazione di coltelleria e utensili manuali",
	"Fabbricazione di metalli strutturali e per l’architettura",
	"Fabbricazione di caldaie, serbatoi e container per il trasporto",
	"Fabbricazione di ferramenta per l’edilizia",
	"Fabbricazione di prodotti fabbricati con fili metallici e molle",
	"Fabbricazione di prodotti torniti ed elementi di fissaggio",
	"Società holding",
	"Trattamenti dei metalli",
	"Associazioni di settore",
	"Servizi paesaggistici",
	"Organizzazioni professionali",
	"Fabbricazione di valvole metalliche, sfere e rulli",
	"Servizi amministrativi e di supporto",
	"Amministrazione d’ufficio",
	"Servizi di ricerca di personale dirigente",
	"Servizi di assistenza temporanei",
	"Fabbricazione di macchinari per l'agricoltura, l'edilizia e l'industria mineraria",
	"Call center telefonici",
	"Agenzie di riscossione",
	"Fabbricazione di macchinari per il settore commerciale e dei servizi",
	"Fabbricazione di apparecchiature HVAC e di refrigerazione",
	"Fabbricazione di macchinari per la lavorazione dei metalli",
	"Servizi di vigilanza e sorveglianza",
	"Servizi per sistemi di sicurezza",
	"Fabbricazione di motori e apparecchiature per la trasmissione di potenza",
	"Servizi di pulizia",
	"Raccolta di rifiuti",
	"Trattamento e smaltimento dei rifiuti",
	"Fabbricazione di apparecchiature per le comunicazioni",
	"Fabbricazione di apparecchiature audio e video",
	"Formazione",
	"Fabbricazione di strumenti di misurazione e controllo",
	"Scuole di segreteria",
	"Formazione tecnica e professionale",
	"Fabbricazione di supporti magnetici e ottici",
	"Scuole di cosmetologia e di formazione per barbieri",
	"Addestramento al volo",
	"Fabbricazione di apparecchiature per illuminazione elettrica",
	"Scuole di belle arti",
	"Formazione per attività sportive e ricreative",
	"Fabbricazione di elettrodomestici",
	"Scuole di lingua",
	"Medici",
	"Tribunali",
	"Istituti di correzione",
	"Dentisti",
	"Vigili del fuoco",
];

const anniDiEsperienzaOptions = ["Meno di 1 anno", "1-2 anni", "3-5 anni", "6-10 anni", "Più di 10 anni"];

const linguaOptions = [
	"Italiano",
	"Arabo",
	"Inglese",
	"Spagnolo",
	"Portoghese",
	"Cinese",
	"Francese",
	"Russo",
	"Tedesco",
	"Olandese",
	"Turco",
	"Tagalog",
	"Polacco",
	"Coreano",
	"Giapponese",
	"Malese",
	"Norvegese",
	"Danese",
	"Rumeno",
	"Svedese",
	"Bahasa Indonesia",
	"Ceco",
];

const funzioniOptions = [
	"Contabilità",
	"Amministrativo",
	"Arte e design",
	"Business Development",
	"Comunità e servizi sociali",
	"Consulenza",
	"Formazione",
	"Ingegneria",
	"Imprenditorialità",
	"Finanza",
	"Sanità",
	"Risorse umane",
	"Informatica",
	"Legale",
	"Marketing",
	"Media e comunicazione",
	"Servizio militare e di protezione",
	"Operazioni",
	"Product Management",
	"Program e Project Management",
	"Acquisti",
	"Controllo qualità",
	"Immobiliare",
	"Ricerca",
	"Vendite",
	"Assistenza",
];

const generateOptions = options => {
	let res = [];
	options.forEach(option => {
		res.push({
			label: option,
			value: option,
		});
	});
	return res;
};

const inputPadding = { style: { padding: 8, width: "fit-content", minWidth: "200px" } };

export default class LeadsListTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			page: 1,
			total: 0,
			filter: "",
			modal: {
				show: false,
				fullName: "",
				id: undefined,
			},
			modalText: {
				show: false,
				id: 0,
				content: "",
			},
			toastAlert: {
				open: false,
				message: "",
				severity: "",
			},
			utenti: [],
			modalNuovaLista: false,
		};
	}

	componentDidMount() {
		this.reload();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.toastAlert.open !== this.state.toastAlert.open && this.state.toastAlert.open) {
			setTimeout(() => {
				this.setState({
					toastAlert: {
						open: false,
						message: "",
						severity: "",
					},
				});
			}, 3000);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedUser !== this.props.selectedUser) {
			this.reload();
		}
	}

	async reload() {
		await getListeCreazione(username, token, this.props.selectedUser).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento della lista",
						severity: "error",
					},
				});
				return;
			}

			this.setState({ rows: res.liste });
		});

		await getUtenti(username, token).then(res => {
			if (res["result"] === "KO") {
				this.setState({
					toastAlert: {
						open: true,
						message: "Errore durante il caricamento della lista",
						severity: "error",
					},
				});
				return;
			}
			this.setState({ utenti: res.profili });
		});
	}

	handleAddLista = () => {
		this.setState({
			modalNuovaLista: true,
			new_list: {
				name_cliente: "",
				stato_creazione: "Non elaborata",
				localita: [],
				localita_da_escludere: [],
				numero_dipendenti: [],
				parole_chiave: "",
				anni_di_esperienza: [],
				settore: [],
				lingua: "",
				settori_new: [],
				numero_dipendenti_new: [],
				anni_di_esperienza_new: [],
				funzioni: [],
				funzioni_new: [],
			},
			localita_input: "",
			localita_da_escludere_input: "",
		});
	};

	cancel = () => {
		let modal = this.state.modal;
		modal.show = false;
		this.setState({ modal });
	};

	handleFilter = async filter => {
		let res = await filterListCreate(username, token, filter, this.props.selectedUser);
		this.setState({ rows: res.liste, filter });
	};

	closeToastAlert = () => {
		this.setState({
			toastAlert: {
				open: false,
				message: "",
				severity: "",
			},
		});
	};

	handleDelete = async id => {
		let res = await deleteList(username, token, id);
		if (res.result === "ok") {
			await getListeCreazione(username, token, this.props.selectedUser).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento della lista",
							severity: "error",
						},
					});
					return;
				} else {
					this.setState({
						toastAlert: {
							open: true,
							message: "Lista eliminata con successo",
							severity: "success",
						},
						rows: res.liste,
					});
				}
			});
		}
	};

	handleSave = async () => {
		if (!this.state.new_list.name_cliente) {
			alert("Il nome della lista è obbligatorio");
			return;
		}

		let localita = this.state.new_list.localita.map(localita => {
			return localita.split(" (")[0];
		});

		let localita_da_escludere = this.state.new_list.localita_da_escludere.map(localita => {
			return localita.split(" (")[0];
		});

		let res = await addListaCreate(
			username,
			token,
			this.state.new_list.name_cliente,
			this.state.new_list.stato_creazione,
			localita.join("||"),
			this.state.new_list.funzioni.join("||"),
			this.state.new_list.numero_dipendenti.join("||"),
			this.state.new_list.parole_chiave,
			this.state.new_list.anni_di_esperienza.join("||"),
			this.state.new_list.settore.join("||"),
			this.state.new_list.lingua,
			localita_da_escludere.join("||"),
			this.props.selectedUser
		);
		if (res.result === "ok") {
			await getListeCreazione(username, token, this.props.selectedUser).then(res => {
				if (res["result"] === "KO") {
					this.setState({
						toastAlert: {
							open: true,
							message: "Errore durante il caricamento della lista",
							severity: "error",
						},
					});
					return;
				} else {
					this.reload();
					this.setState({
						toastAlert: {
							open: true,
							message: "Lista aggiunta con successo",
							severity: "success",
						},
						modalNuovaLista: false,
					});
				}
			});
		} else {
			this.setState({
				toastAlert: {
					open: true,
					message: "Errore durante il caricamento della lista.",
					severity: "error",
				},
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.toastAlert.open !== this.state.toastAlert.open && this.state.toastAlert.open) {
			setTimeout(() => {
				this.setState({
					toastAlert: {
						open: false,
						message: "",
						severity: "",
					},
				});
			}, 3000);
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
					<CircularProgress />
				</div>
			);
		}

		return (
			<div
				style={{
					width: "96%",
					marginInline: "auto",
					marginTop: "20px",
					marginBottom: "20px",
					maxHeight: "90%",
					overflow: "hidden",
				}}
			>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={this.state.toastAlert.open}
						autoHideDuration={6000}
						onClose={this.handleCloseAlert}
						anchorOrigin={{ horizontal: "right", vertical: "top" }}
					>
						<Alert
							onClose={this.handleCloseAlert}
							severity={this.state.toastAlert.severity}
							sx={{ width: "100%" }}
						>
							{this.state.toastAlert.message}
						</Alert>
					</Snackbar>
				</Stack>
				{this.state.modalNuovaLista && (
					<Dialog
						maxWidth="lg"
						fullWidth={true}
						open={this.state.modalNuovaLista}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.setState({ modalNuovaLista: false })}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogTitle>Aggiungi una nuova licdcdcsta</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Compila tutti i campi per aggiungere una nuova lista
							</DialogContentText>
						</DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Nome"
										variant="standard"
										value={this.state.new_list.name_cliente}
										onChange={event => {
											let new_list = this.state.new_list;
											new_list.name_cliente = event.target.value;
											this.setState({ new_list });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<MapsInput
										label={"Località"}
										onClick={localita => {
											let new_list = this.state.new_list;
											new_list.localita.push(localita);
											this.setState({ new_list });
										}}
									/>
									{this.state.new_list.localita.map((localita, i) => {
										return (
											<Chip
												key={i}
												label={localita}
												onDelete={() => {
													let new_list = this.state.new_list;
													new_list.localita.splice(i, 1);
													this.setState({ new_list });
												}}
												style={{ margin: "4px" }}
											/>
										);
									})}
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<MapsInput
										label={"Località da escludere"}
										onClick={localita => {
											let new_list = this.state.new_list;
											new_list.localita_da_escludere.push(localita);
											this.setState({ new_list });
										}}
									/>
									{this.state.new_list.localita_da_escludere.map((localita, i) => {
										return (
											<Chip
												key={i}
												label={localita}
												onDelete={() => {
													let new_list = this.state.new_list;
													new_list.localita_da_escludere.splice(i, 1);
													this.setState({ new_list });
												}}
												style={{ margin: "4px" }}
											/>
										);
									})}
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona settore</InputLabel>
										<MultiSelect
											options={generateOptions(settoriOptions)}
											value={this.state.new_list.settori_new}
											onChange={event => {
												let new_settori = [];
												event.map(settore => {
													new_settori.push(settore.value);
												});
												this.setState({
													new_list: {
														...this.state.new_list,
														settore: new_settori,
														settori_new: event,
													},
												});
											}}
											labelledBy="settori"
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona numero dipendenti</InputLabel>
										<MultiSelect
											options={generateOptions(numeroDipendentiOptions)}
											value={this.state.new_list.numero_dipendenti_new}
											onChange={event => {
												let new_numero_dipendenti = [];
												event.map(numero_dipendenti => {
													new_numero_dipendenti.push(numero_dipendenti.value);
												});
												this.setState({
													new_list: {
														...this.state.new_list,
														numero_dipendenti: new_numero_dipendenti,
														numero_dipendenti_new: event,
													},
												});
											}}
											labelledBy="numero_dipendenti"
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona anni di esperienza</InputLabel>
										<MultiSelect
											options={generateOptions(anniDiEsperienzaOptions)}
											value={this.state.new_list.anni_di_esperienza_new}
											onChange={event => {
												let new_anni_di_esperienza = [];
												event.map(anni_di_esperienza => {
													new_anni_di_esperienza.push(anni_di_esperienza.value);
												});
												this.setState({
													new_list: {
														...this.state.new_list,
														anni_di_esperienza: new_anni_di_esperienza,
														anni_di_esperienza_new: event,
													},
												});
											}}
											labelledBy="anni_di_esperienza"
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona le funzioni</InputLabel>
										<MultiSelect
											options={generateOptions(funzioniOptions)}
											value={this.state.new_list.funzioni_new}
											onChange={event => {
												let new_funzioni = [];
												event.map(funzione => {
													new_funzioni.push(funzione.value);
												});
												this.setState({
													new_list: {
														...this.state.new_list,
														funzioni: new_funzioni,
														funzioni_new: event,
													},
												});
											}}
											labelledBy="funzioni"
										/>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<FormControl fullWidth>
										<InputLabel>Seleziona lingua</InputLabel>
										<Select
											label="Lingua"
											variant="standard"
											value={this.state.new_list.lingua}
											onChange={event => {
												let new_list = this.state.new_list;
												new_list.lingua = event.target.value;
												this.setState({ new_list });
											}}
											fullWidth
										>
											{linguaOptions.map((option, i) => (
												<MenuItem value={option}>{option}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box paddingX={4} paddingY={1}>
									<TextField
										label="Parole chiave"
										variant="standard"
										value={this.state.new_list.parole_chiave}
										onChange={event => {
											let new_list = this.state.new_list;
											new_list.parole_chiave = event.target.value;
											this.setState({ new_list });
										}}
										fullWidth
									/>
								</Box>
							</Grid>
						</Grid>
						<DialogActions>
							<Button variant="contained" onClick={() => this.setState({ modalNuovaLista: false })}>
								Chiudi
							</Button>
							<Button variant="contained" className="myButton" onClick={this.handleSave}>
								Salva
							</Button>
						</DialogActions>
					</Dialog>
				)}

				{/* HEADER */}
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<TextField
							label="Cerca"
							variant="standard"
							className="p-0 me-2 mb-2"
							onChange={event => {
								this.setState({ filter: event.target.value });
							}}
							onKeyDown={event => {
								if (event.key === "Enter") {
									this.handleFilter(event.target.value);
								}
							}}
							onBlur={event => {
								this.handleFilter(event.target.value);
							}}
						/>
					</div>
					<Button variant="contained" className="myButton" onClick={this.handleAddLista}>
						<FontAwesomeIcon icon={faPlus} style={{ marginRight: "0.5rem" }} />
						Aggiungi Lista
					</Button>
				</div>
				{/* TABLE */}
				<TableContainer component={Paper} sx={{ overflow: "auto", height: "80vh" }}>
					<Table striped bordered hover responsive>
						<TableHead>
							<TableRow>
								{headCells.map(headCell => (
									<TableCell key={headCell.id}>{headCell.label}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.rows.length > 0 &&
								this.state.rows.map((row, i) => (
									<TableRow key={row.id}>
										<TableCell>
											<TextField {...inputPadding} value={row.name_cliente} />
										</TableCell>
										<TableCell>
											{type === "1" ? (
												<FormControl fullWidth>
													<InputLabel>Stato</InputLabel>
													<Select
														variant="standard"
														value={row.stato_creazione}
														onChange={async event => {
															let rows = this.state.rows;
															rows[i].stato_creazione = event.target.value;
															this.setState({ rows });
															await editListCreate(username, token, rows[i]).then(res => {
																if (res.result === "ok") {
																	this.setState({
																		toastAlert: {
																			open: true,
																			message: "Lista modificata con successo",
																			severity: "success",
																		},
																	});
																} else {
																	this.setState({
																		toastAlert: {
																			open: true,
																			message:
																				"Errore durante la modifica della lista",
																			severity: "error",
																		},
																	});
																}
															});
														}}
														fullWidth
													>
														<MenuItem value={"Non elaborata"}>Non elaborata</MenuItem>
														<MenuItem value={"In elaborazione"}>In elaborazione</MenuItem>
														<MenuItem value={"Elaborata"}>Elaborata</MenuItem>
													</Select>
												</FormControl>
											) : (
												<TextField {...inputPadding} value={row.stato_creazione} />
											)}
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.localita} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.localita_da_escludere} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.settore} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.funzioni} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.numero_dipendenti} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.anni_di_esperienza} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.lingua} />
										</TableCell>
										<TableCell>
											<TextField {...inputPadding} value={row.parole_chiave} />
										</TableCell>
										<TableCell>
											<Tooltip title="Elimina lista">
												<Button
													onClick={() =>
														window.confirm(
															`Sei sicuro di voler eliminare la lista ${row.name_cliente} ?`
														)
															? this.handleDelete(row.id)
															: null
													}
													color="inherit"
													variant="text"
												>
													<FontAwesomeIcon size={"lg"} icon={faTrashCan} />
												</Button>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}
